import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import RelativeTimestamp from '../../utils/timeago';
import { variants } from '../../utils/variants';
import { checkFallbackImage } from '../../utils/utility';

import { ReactComponent as IconClose } from '../../icons/icon__close.svg';

import styles from './article.module.scss';

// import { Readability } from "@mozilla/readability";
// import { JSDOM } from "jsdom";

export default function Article(props) {
  const [readArticle, setReadArticle] = useState(false);
  const { title, feed, pub, description, link } = props.data;
  const ref = useRef(null);

  const variantsYT = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 },
    delay: 1,
  };

  const newTitle = (l) => {
    try {
      const url = new URL(l);
      const feedNew = url.hostname.replace('www.', '');
      return feedNew;
    } catch (error) {
      return l;
    }
  }

  // const parseHTML = (htmlString) => {
  //   const parser = new DOMParser();
  //   const doc = parser.parseFromString(htmlString, "text/html");
  //   return doc.body; // Returns the body of the parsed HTML
  // };

  // const getArticle = async (url) => {
  //   const response = await fetch(url, {
  //     headers: {
  //       'Access-Control-Allow-Origin': '*'
  //     }
  //   });
  //   const html = await response.text();
  //   const dom = parseHTML(html);
  //   const reader = new Readability(dom);
  //   const article = reader.parse();
  //   return article;
  // }

  // const handleClick = (e, tag) => {
  //   e.preventDefault();
  //   props.tagNav(tag);
  // }

  const handlePubClick = (e, publisher) => {
    e.preventDefault();
    props.pubNav(publisher);
  }

  const handleClickReadArticle = (e) => {
    e.preventDefault();
    setReadArticle(!readArticle);
    props.innerModalActive(!readArticle);
  }

  const handleScroll = (e) => {
    e.preventDefault();

    if (ref.current) {
      const height = ref.current.offsetHeight / 2;
      const scrollDistance = e.deltaY > 0 ? height : -height;

      ref.current.scrollBy({
        top: scrollDistance,
        behavior: 'smooth',
      });
    }
  };

  // useEffect(() => {
  //   // "http://localhost:4800/html?u=https://qz.com/a-timeline-of-all-the-ways-driverless-vehicles-failed-i-1851104020"
  //   const articleContent = getArticle(link);
  // }, []);

  useEffect(() => {
    const handleEsc = (e) => {
      if (e.key === 'Escape') {
        setReadArticle(false);
        props.innerModalActive(false);
      }
    }
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [props]);

  useEffect(() => {
    if (props.data.html) {
      const parser = new DOMParser();
      const parsedHtml = parser.parseFromString(props.data.html, 'text/html');
      const links = parsedHtml.getElementsByTagName('a');
      for (let i = 0; i < links.length; i++) {
        links[i].setAttribute('target', '_blank');
      }
      props.data.html = parsedHtml.body.innerHTML;
    }
  }, [props.data]);

  if (!props.data) return null;

  return (
    <motion.article
      key={`article-${title}`}
      initial="hidden"
      whileInView="visible"
      variants={variants}
      className={
        props.fullscreen ? `${styles.outer} ${styles.fullscreen}` : styles.outer
      }
      style={{ overflow: readArticle ? "hidden" : "inherit" }}
    >
      <div className={styles.glow} />
      <AnimatePresence key={`read-article-${title}`}>
        {props.data.html && readArticle && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={variantsYT}
            transition={{
              duration: 0.666,
              ease: "easeInOut",
            }}
            key={[readArticle, props.data.html]}
          >
            <IconClose
              className={styles.close}
              onClick={(e) => handleClickReadArticle(e)}
            />
            <div className={styles.preview}>
              <div
                dangerouslySetInnerHTML={{ __html: props.data.html }}
                className={styles.previewInner}
                onWheel={handleScroll}
                ref={ref}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.article}
      >
        <div
          className={styles.image}
          onClick={(e) =>
            props.data.html ? handleClickReadArticle(e, link) : null
          }
        >
          <img
            src={checkFallbackImage(props.data.image)}
            alt={props.data.type.substring(0, 12)}
            loading="lazy"
          />
        </div>
        <div className={styles.content}>
          <div className={styles.meta}>
            <div className={styles.metaInner}>
              <img
                src={`https://www.google.com/s2/favicons?domain=${link}&sz=64`}
                alt={link.substring(0, 12)}
                width={22}
                height={22}
                loading="lazy"
              />
              <span
                className={styles.channel}
                onClick={(e) => {
                  if (!feed) return;
                  handlePubClick(e, feed);
                }}
              >
                {feed ? feed : newTitle(link)}
              </span>
            </div>
            {pub && (
              <div className={styles.duration}>
                <RelativeTimestamp timestamp={pub} />
              </div>
            )}
          </div>
          {title && (
            <div className={styles.contentInner}>
              <h3 className={styles.title}>{title}</h3>
              <div className={styles.description}>
                <p>{description}</p>
              </div>
            </div>
          )}
          {/* {tags && Array.isArray(tags) && (
            <div className={styles.tags}>
              {tags.slice(0, 6).map((tag, index) => {
                if (typeof tag === "object") {
                  return null;
                }

                return (
                  <span
                    key={`tag-${tag}-${index}`}
                    className={styles.tag}
                    onClick={(e) => handleClick(e, tag)}
                  >
                    {tag.length > 24 ? tag.slice(0, 24) + "..." : tag}
                  </span>
                );
              })}
            </div>
          )} */}
        </div>
      </a>
    </motion.article>
  );
}