import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

import { getData } from "../../utils/data";

import { variants } from "../../utils/variants";

import styles from "./links.module.scss";
// import RelativeTimestamp from '../../utils/timeago';

export default function Links() {
  const [youtube_channels, setYoutubeChannels] = useState([]);
  const [twitch_channels, setTwitchChannels] = useState([]);

  const builds = [
    {
      link: "https://www.pathofexile.com/trade",
      override: "https://www.pathofexile.com",
      title: "Official Trade",
      description: "Trade Site",
      feed: "Official Trade",
    },
    {
      link: "https://pobarchives.com",
      override: "https://pobarchives.com",
      title: "PoB Archive",
      description:
        "Find your next Path of Building recipe between 7410 builds.",
      feed: "PoB Archive",
    },
    {
      link: "https://pob.cool",
      override: "https://pob.cool",
      title: "PoB Cool",
      description: "A web version of Path of Building.",
      feed: "PoB Cool",
    },
    {
      link: "https://poemap.trade",
      override: "https://poemap.trade",
      title: "PoeMap.Trade",
      description: "Map Trade Site",
      feed: "PoeMap.Trade",
    },
    {
      link: "https://github.com/unremem/PoBTradeHelper",
      override: "https://github.com",
      title: "PoBTradeHelper",
      description: "Trade Chrome Extension",
      feed: "PoBTradeHelper",
    },
    {
      link: "https://poe.ninja/challenge/builds",
      override: "https://poe.ninja",
      title: "Poe.Ninja",
      description: "Builds & Economy",
      feed: "Poe.Ninja",
    },
    {
      link: "https://www.poebuilds.cc",
      override: "https://www.poebuilds.cc",
      title: "Poe Builds",
      description: "Builds",
      feed: "Poe Builds",
    },
    {
      link: "https://poe-profile.info/",
      override: "https://poe-profile.info/",
      title: "PoE Profile Info",
      description: "ere you can see Skill Gems, Items and Combined Stats Data",
      feed: "PoE Profile Info",
    },
    {
      link: "https://bpl-poe.com",
      override: "https://bpl-poe.com",
      title: "BPL",
      description:
        "BPL is a cooperative, team-based Path of Exile community event",
      feed: "BPL",
    },
    {
      link: "https://maxroll.gg/poe/",
      override: "https://maxroll.gg/poe/",
      title: "Maxroll",
      description:
        "Maxroll covers Path of Exile Build Guides, League Starters, Boss Guides, Mechanics, Tools and PoE Currency Farming Guides.",
      feed: "Maxroll",
    },
    {
      link: "https://www.poe-vault.com/",
      override: "https://www.poe-vault.com/",
      title: "PoE Vault",
      description: "Path of Exile Builds, Guides, Database, and Tracker",
      feed: "PoE Vault",
    },
    {
      link: "https://www.pohx.net/",
      override: "https://www.pohx.net/",
      title: "Pohx RF Wiki",
      description: "Righteous Fire Wiki",
      feed: "Pohx RF Wiki",
    },
    {
      link: "https://heartofphos.github.io/exile-leveling/",
      override: "https://heartofphos.github.io/exile-leveling/",
      title: "Exile Leveling",
      description: "Campaign Leveling Guide",
      feed: "Exile Leveling",
    },
    {
      link: "https://poe-leveling.com/",
      override: "https://poe-leveling.com/",
      title: "PoE Leveling",
      description: "Campaign Leveling Guide",
      feed: "PoE Leveling",
    },
  ];

  const tools = [
    {
      link: "https://www.pathofexile.com/trade/exchange/Affliction",
      override: "https://www.pathofexile.com/trade/exchange/Affliction",
      title: "Bulk Item Exchange",
      description: "Official Site for Bulk Item Exchange",
      feed: "Bulk Item Exchange",
    },
    {
      link: "https://github.com/ChaosRecipeEnhancer/EnhancePoEApp",
      override: "https://github.com/ChaosRecipeEnhancer/EnhancePoEApp",
      title: "EnhancePoEApp",
      description: "Streamline your Chaos Recipe gains!",
      feed: "EnhancePoEApp",
    },
    {
      link: "https://poestrat.com/",
      override: "https://poestrat.com/",
      title: "PoE Strat",
      description: "Tools to help play PoE",
      feed: "Bulk Item Exchange",
    },
    {
      link: "https://www.pathofexile.com/item-filter/ladder/follower",
      override: "https://www.pathofexile.com/item-filter/ladder/follower",
      title: "Official Item Filter",
      description: "Ladder for Shared Item Filters",
      feed: "Official Item Filter",
    },
    {
      link: "https://poe.re/",
      override: "https://poe.re/",
      title: "PoE Regex",
      description: "Regex for Path of Exile",
      feed: "PoE Regex",
    },
    {
      link: "https://github.com/Procurement-PoE/Procurement",
      override: "https://github.com/Procurement-PoE/Procurement",
      title: "Procurement",
      description: "Path Of Exile Character & Stash Management Tool",
      feed: "Procurement",
    },
    {
      link: "https://github.com/NeverSinkDev/NeverSink-Filter",
      override: "https://github.com/NeverSinkDev/NeverSink-Filter",
      title: "NeverSink-Filter",
      description:
        "This is a lootfilter by NeverSInk. It hides low value items, uses a markup-scheme and sounds to highlight expensive gear and is based on economy data mining. ",
      feed: "NeverSink-Filter",
    },
    {
      link: "https://github.com/Kyusung4698/PoE-Overlay",
      override: "https://github.com/Kyusung4698/PoE-Overlay",
      title: "PoE-Overlay",
      description:
        "An Overlay for Path of Exile. Built with Overwolf and Angular.",
      feed: "PoE-Overlay",
    },
    {
      link: "https://apps.apple.com/us/app/exile-companion-poe/id1457210391",
      override:
        "https://apps.apple.com/us/app/exile-companion-poe/id1457210391",
      title: "Exile Companion (PoE)",
      description: "Unofficial PoE companion app for iOS",
      feed: "Exile Companion (PoE)",
    },
    {
      link: "https://github.com/JusKillmeQik/PoE-Leveling-Guide",
      override: "https://github.com/JusKillmeQik/PoE-Leveling-Guide",
      title: "PoE-Leveling-Guide",
      description: "Autohotkey overlay macro for leveling in Path of Exile",
      feed: "PoE-Leveling-Guide",
    },
    {
      link: "https://github.com/zmilla93/SlimTrade",
      override: "https://github.com/zmilla93/SlimTrade",
      title: "SlimTrade",
      description: "A trade macro overlay for the game Path of Exile",
      feed: "SlimTrade",
    },
    {
      link: "https://www.divinetoll.com/about",
      override: "https://www.divinetoll.com/about",
      title: "Divine Toll",
      description: "A price index for the Path of Exile Divine Orb exchange.",
      feed: "Divine Toll",
    },
    {
      link: "https://github.com/dermow/TraXile",
      override: "https://github.com/dermow/TraXile",
      title: "TraXile",
      description:
        "A tool that records activities and statistics for your Path of Exile",
      feed: "TraXile",
    },
    {
      link: "https://vilsol.github.io/timeless-jewels",
      override: "https://vilsol.github.io/timeless-jewels",
      title: "Timeless Calculator",
      description: "Timeless Jewel Calculator",
      feed: "Timeless Calculator",
    },
    {
      link: "https://cwdt.info/",
      override: "https://cwdt.info/",
      title: "CWDT Calculator",
      description: "Looper Build Generator",
      feed: "CWDT Calculator",
    },
    {
      link: "https://github.com/maxensas/xiletrade",
      override: "https://github.com/maxensas/xiletrade",
      title: "Xiletrade",
      description:
        "Path of exile addon that helps live pricing in-game items and currencies by using official GGG's APIs, includes additional features to make your life easier.",
      feed: "Xiletrade",
    },
    {
      link: "https://poe.how/economy",
      override: "https://poe.how/economy",
      title: "poeHow",
      description:
        "Your gateway to untold prosperity in this epic realm of Wraeclast",
      feed: "poeHow",
    },
    {
      link: "https://filter-of-kalandra.xyz/",
      override: "https://filter-of-kalandra.xyz/",
      title: "Filter of Kalandra",
      description: "Maintain Complex Loot Filters",
      feed: "Filter of Kalandra",
    },
    {
      link: "https://divicards-site.pages.dev",
      override: "https://divicards-site.pages.dev",
      title: "Divi Cards",
      description: "Index of Divination Cards",
      feed: "Divi Cards",
    },
    {
      link: "https://github.com/shonya3/divicards",
      override: "https://github.com/shonya3/divicards",
      title: "Divicards",
      description: "Desktop Application for Path of Exile divination cards",
      feed: "Divicards",
    },
    {
      link: "https://poeatlas.net/",
      override: "https://poeatlas.net/",
      title: "PoE Atlas",
      description:
        "A tool for navigating Path of Exile's Atlas of Worlds from the web",
      feed: "PoE Atlas",
    },
    {
      link: "https://github.com/sushibagel/PoE-Mechanic-Watch",
      override: "https://github.com/sushibagel/PoE-Mechanic-Watch",
      title: "PoE-Mechanic-Watch",
      description:
        "AHK Script that watches the Path of Exile Client.txt and warns the user to complete certain mechanics. ",
      feed: "PoE-Mechanic-Watch",
    },
    {
      link: "https://www.pathofexile.com/developer/docs",
      override: "https://www.pathofexile.com/developer/docs",
      title: "Path of Exile Developer Docs",
      description: "Basic set of API endpoints for Path of Exile",
      feed: "Path of Exile Developer Docs",
    },
    {
      link: "https://github.com/nomis51/Menagerie/",
      override: "https://github.com/nomis51/Menagerie/",
      title: "Menagerie",
      description:
        "A Path of Exile trade manager to simplify and speed up your trading experience, and much more!",
      feed: "Menagerie",
    },
    {
      link: "https://chromewebstore.google.com/detail/better-pathofexile-tradin/fhlinfpmdlijegjlpgedcmglkakaghnk",
      override:
        "https://chromewebstore.google.com/detail/better-pathofexile-tradin/fhlinfpmdlijegjlpgedcmglkakaghnk",
      title: "Better PathOfExile Trading",
      description:
        "A browser extension that enhances the pathofexile.com/trade experience.",
      feed: "Better PathOfExile Trading",
    },
    {
      link: "https://www.pathofexile.com/forum/view-thread/1363453",
      override: "https://www.pathofexile.com/forum/view-thread/1363453",
      title: "PoE Mate",
      description: "Beginner's Tips section, Mana Sustain Calculator",
      feed: "PoE Mate",
    },
    {
      link: "https://www.buildofexile.com/",
      override: "https://www.buildofexile.com/",
      title: "Build of Exile",
      description: "Indexed builds from the official Path of Exile",
      feed: "Build of Exile",
    },
    {
      link: "https://poe-racing.com",
      override: "https://poe-racing.com",
      title: "PoE Racing",
      description:
        "Keep track of your own or your favorite streamer's progress in the current league or racing event.",
      feed: "PoE Racing",
    },
    {
      link: "https://www.reddit.com/r/pathofexile/comments/2cui3l/mtx_finder_done/",
      override:
        "https://www.reddit.com/r/pathofexile/comments/2cui3l/mtx_finder_done/",
      title: "MTX Finder Bookmarklet",
      description: "MTX Finder Bookmarklet",
      feed: "MTX Finder Bookmarklet",
    },
    {
      link: "https://siveran.github.io/calc.html",
      override: "https://siveran.github.io/calc.html",
      title: "Vorici Chromatic Calculator",
      description:
        "Path of Exile chromatic cost calculator for Vorici master crafting and raw chromatic orbs.",
      feed: "Vorici Chromatic Calculator",
    },
    {
      link: "https://hideoutshowcase.com/",
      override: "https://hideoutshowcase.com/",
      title: "Hideout Showcase",
      description: "Upload your hideouts, rate others and loads more.",
      feed: "Hideout Showcase",
    },
    {
      link: "https://github.com/ChaosRecipeEnhancer/EnhancePoEApp",
      override: "https://github.com/ChaosRecipeEnhancer/EnhancePoEApp",
      title: "EnhancePoEApp",
      description: "Streamline your Chaos Recipe gains! ",
      feed: "EnhancePoEApp",
    },
    {
      link: "https://exile-tools.com/",
      override: "https://exile-tools.com/",
      title: "Exile Tools",
      description: "Tools By TraXile",
      feed: "Exile Tools",
    },
    {
      link: "https://poegems.com/",
      override: "https://poegems.com/",
      title: "PoE Gems",
      description:
        "Find the skill gems you need for your Path of Exile character, searching through all active and support gems available in the game.",
      feed: "PoE Gems",
    },
    {
      link: "https://poesyn.xyz/syndicate",
      override: "https://poesyn.xyz/syndicate",
      title: "PoE Syndicate Cheat Sheet",
      description: "Betrayal",
      feed: "PoE Syndicate Cheat Sheet",
    },
    {
      link: "https://poe-antiquary.xyz/",
      override: "https://poe-antiquary.xyz/",
      title: "PoE Antiquary",
      description:
        "Plan your league starter build around items that are affordable in the first days of a new league",
      feed: "PoE Antiquary",
    },
    {
      link: "https://mapwatch.erosson.org/",
      override: "https://mapwatch.erosson.org/",
      title: "MapWatch",
      description:
        "Give me your Path of Exile Client.txt file, and I'll give you some statistics about your recent mapping activity.",
      feed: "MapWatch",
    },
    {
      link: "https://www.poeprices.info/",
      override: "https://www.poeprices.info/",
      title: "PoE Prices",
      description:
        "A simple tool to price check your items in path of exile by 'copy and paste'. It is that simple! Support Google Chrome, Firefox and Safari",
      feed: "PoE Prices",
    },
    {
      link: "https://blight.raelys.com/",
      override: "https://blight.raelys.com/",
      title: "Blight Helper",
      description: "A helper for Path of Exile's Blight",
      feed: "Blight Helper",
    },
    {
      link: "https://github.com/Qt-dev/exile-diary",
      override: "https://github.com/Qt-dev/exile-diary",
      title: "Exile Diary Reborn",
      description: "A game tracker for Path of Exile",
      feed: "Exile Diary Reborn",
    },
    {
      link: "https://poe.watch/",
      override: "https://poe.watch/",
      title: "PoE Watch",
      description:
        "poe.watch helps you to see realtime pricing information about all items in the game.",
      feed: "PoE Watch",
    },
    {
      link: "https://docs.google.com/spreadsheets/d/1fIs8sdvgZG7iVouPdtFkbRx5kv55_xVja8l19yubyRU/edit#gid=1983365983",
      override:
        "https://docs.google.com/spreadsheets/d/1fIs8sdvgZG7iVouPdtFkbRx5kv55_xVja8l19yubyRU/edit#gid=1983365983",
      title: "PoE 3.23 Cheat Sheets (QOL Info)",
      description: "Google Sheet with QOL Info",
      feed: "PoE 3.23 Cheat Sheets (QOL Info)",
    },
    {
      link: "https://github.com/PathOfBuildingCommunity/PathOfBuilding/wiki/Installing-this-Fork",
      override: "https://www.github.com",
      title: "Path of Building Community Fork",
      description: "Offline build planner",
      feed: "Path of Building Community Fork",
    },
    {
      link: "https://poeplanner.com",
      override: "https://poeplanner.com",
      title: "PoE Planner",
      description: "Online Build Planner",
      feed: "PoE Planner",
    },
    {
      link: "https://www.pathofexile.com/passive-skill-tree",
      override: "https://www.pathofexile.com",
      title: "Official Skill Tree Planner",
      description: "Online Build Planner",
      feed: "Official Skill Tree Planner",
    },
    {
      link: "https://poeskilltree.com",
      override: "https://poeskilltree.com",
      title: "Skill Tree Planner",
      description: "Online Build Planner",
      feed: "Skill Tree Planner",
    },
    {
      link: "https://www.pathofpathing.com",
      override: "https://www.pathofpathing.com",
      title: "Path of Pathing",
      description: "Online Atlas Tree Planner",
      feed: "Path of Pathing",
    },
    {
      link: "https://www.craftofexile.com",
      override: "https://www.craftofexile.com",
      title: "Craft of Exile",
      description:
        "Craft of Exile is a crafting simulator for Path of Exile designed to compute the probabilities of obtaining specific results through different methods",
      feed: "Craft of Exile",
    },
    {
      link: "https://poedb.tw/us",
      override: "https://poedb.tw/us",
      title: "PoEDB",
      description: "Database for Path of Exile",
      feed: "PoEDB",
    },
    {
      link: "https://poe2db.tw/",
      override: "https://poe2db.tw/",
      title: "PoE2DB",
      description: "Database for Path of Exile 2",
      feed: "PoE2DB",
    },
    {
      link: "https://pobb.in",
      override: "https://pobb.in",
      title: "PoBB",
      description: "Online Skill Planner & Share Links",
      feed: "PoBB",
    },
    {
      link: "https://divcards.io/",
      override: "https://divcards.io/",
      title: "Div Cards But Better",
      description: "Div Cards",
      feed: "Div Cards But Better",
    },
    {
      link: "https://www.poelab.com/dp8bo",
      override: "https://www.poelab.com/dp8bo",
      title: "PoE Lab",
      description: "Labyrinth Layouts",
      feed: "PoE Lab",
    },
    {
      link: "https://www.poebuddy.xyz/",
      override: "https://www.poebuddy.xyz/",
      title: "PoE Buddy",
      description: "An in-browser read-only Path of Building viewer.",
      feed: "PoE Buddy",
    },
    {
      link: "https://cheatsheet.monster/poe/",
      override: "https://cheatsheet.monster/poe/",
      title: "Cheatsheet.Monster",
      description: "Divines are the new Exalted.",
      feed: "Cheatsheet.Monster",
    },
    {
      link: "https://github.com/willhuynh/VendorRecipeCheatsheet",
      override: "https://github.com/willhuynh/VendorRecipeCheatsheet",
      title: "VendorRecipeCheatsheet",
      description: "Vendor Recipe Cheatsheet",
      feed: "VendorRecipeCheatsheet",
    },
    {
      link: "https://www.filterblade.xyz",
      override: "https://www.filterblade.xyz",
      title: "FilterBlade",
      description: "Loot Filter Customizer",
      feed: "FilterBlade",
    },
    {
      link: "https://github.com/SnosMe/awakened-poe-trade",
      override: "https://github.com",
      title: "Awakened PoE Trade",
      description: "Trading app for price checking",
      feed: "Awakened PoE Trade",
    },
    {
      link: "https://github.com/Morph21/MercuryTrade-Community-Fork",
      override: "https://github.com",
      title: "MercuryTrade-Community-Fork",
      description: "An overlay application (Tracking, Trading, Chat, etc)",
      feed: "MercuryTrade-Community-Fork",
    },
    {
      link: "https://github.com/C1rdec/Poe-Lurker",
      override: "https://github.com",
      title: "Poe Lurker",
      description: "Overlay trade manager",
      feed: "Poe Lurker",
    },
    {
      link: "https://github.com/Lailloken/Lailloken-UI",
      override: "https://github.com",
      title: "Lailloken-UI",
      description: "UI and QoL extension",
      feed: "Lailloken-UI",
    },
    {
      link: "https://github.com/PoE-Overlay-Community/PoE-Overlay-Community-Fork",
      override: "https://github.com",
      title: "PoE Overlay (Community Fork)",
      description: "Overlay",
      feed: "PoE Overlay (Community Fork)",
    },
    {
      link: "https://github.com/exilence-ce/exilence-ce",
      override: "https://github.com",
      title: "Exilence CE",
      description: "Desktop application that helps you summarize your wealth",
      feed: "Exilence CE",
    },
    {
      link: "https://poestack.com",
      override: "https://poestack.com",
      title: "PoE Stack",
      description: "Bulk Selling and Inventory, Currency Tracking",
      feed: "PoE Stack",
    },
    {
      link: "https://mapsofexile.com",
      override: "https://mapsofexile.com",
      title: "Maps of Exile",
      description:
        "Find everything related to maps here, search for maps that drop best divination cards, find map layouts and find maps you want to farm. And by the way, Scourge Arrow is the best skill.",
      feed: "Maps of Exile",
    },
  ];

  const discords = [
    {
      link: "https://discord.gg/pathofexile",
      override: "https://discord.gg/pathofexile",
      title: "Official PoE Discord",
      description: "Path of Exile",
      feed: "Official PoE Discord",
    },
    {
      link: "https://discord.gg/path-of-exile-trading-530668348682403841",
      override: "https://discord.gg/path-of-exile-trading-530668348682403841",
      title: "Path of Exile Tranding",
      description: "Trading Discord",
      feed: "Path of Exile Tranding",
    },
    {
      link: "https://discord.gg/U8xQbqcbba",
      override: "https://discord.gg/U8xQbqcbba",
      title: "PoE Wiki Discord",
      description: "Wiki",
      feed: "PoE Wiki Discord",
    },
    {
      link: "https://discord.gg/eqUBJKmFsm",
      override: "https://discord.gg/eqUBJKmFsm",
      title: "Belton's PoE Market Masters",
      description: "Belton's Discord",
      feed: "Belton's PoE Market Masters",
    },
    {
      link: "https://discord.gg/woolie",
      override: "https://discord.gg/woolie",
      title: "Woolie",
      description: "Woolie",
      feed: "Woolie",
    },
    {
      link: "https://discord.gg/YP6dMzCE2w",
      override: "https://discord.gg/YP6dMzCE2w",
      title: "The Circus",
      description: "Ben",
      feed: "The Circus",
    },
    {
      link: "https://discord.gg/QphHs2xKtT",
      override: "https://discord.gg/QphHs2xKtT",
      title: "Unhinged Exile Society",
      description: "Talkative Tri",
      feed: "Unhinged Exile Society",
    },
    {
      link: "https://discord.gg/jp9vQeHgWY",
      override: "https://discord.gg/jp9vQeHgWY",
      title: "Makers",
      description: "CWDT Discord for Loopers",
      feed: "Makers",
    },
    {
      link: "https://discord.gg/tftrove",
      override: "https://discord.gg/tftrove",
      title: "The Forbidden Trove",
      description: "TFT",
      feed: "The Forbidden Trove",
    },
    {
      link: "https://discord.gg/VWuYwvhYYM",
      override: "https://discord.gg/VWuYwvhYYM",
      title: "Prohibited Library",
      description: "The Path of Exile Science & Data Collection Server",
      feed: "Prohibited Library",
    },
    {
      link: "https://discord.gg/rFk9G2eBaA",
      override: "https://discord.gg/rFk9G2eBaA",
      title: "Cyclon's Hideout",
      description: "Cyclon",
      feed: "Cyclon's Hideout",
    },
    {
      link: "https://discord.gg/",
      override: "https://discord.gg/",
      title: "Divcord",
      description: "Divination Card Data Collection",
      feed: "Divcord",
    },
    {
      link: "https://discord.gg/Mbcz88wTTU",
      override: "https://discord.gg/Mbcz88wTTU",
      title: "The Noodle Fam",
      description: "Kitten Cat Noodle",
      feed: "The Noodle Fam",
    },
    {
      link: "https://discord.gg/f7VrGZS5XN",
      override: "https://discord.gg/f7VrGZS5XN",
      title: "CatCave",
      description: "CatmasterOP",
      feed: "CatCave",
    },
    {
      link: "https://discord.gg/hCNvvxRHHh",
      override: "https://discord.gg/hCNvvxRHHh",
      title: "BPL",
      description:
        "BPL is a cooperative, team-based Path of Exile community event",
      feed: "BPL",
    },
    {
      link: "https://discord.gg/e3cnY4FWQS",
      override: "https://discord.gg/e3cnY4FWQS",
      title: "PoE Stack",
      description:
        "PoE Stack is a cooperative, team-based Path of Exile community event",
      feed: "PoE Stack",
    },
    {
      link: "https://discord.com/invite/path-of-exile-trading-530668348682403841",
      override:
        "https://discord.com/invite/path-of-exile-trading-530668348682403841",
      title: "Path of Exile Trading",
      description: "Trading",
      feed: "Path of Exile Trading",
    },
    {
      link: "https://discord.gg/Wf24Vwb",
      override: "https://discord.gg/Wf24Vwb",
      title: "Path of Exile Console",
      description: "PS4, Xbox, Steam Deck",
      feed: "Path of Exile Console",
    },
    {
      link: "https://discord.com/invite/kobe-s-court-house-of-highlights-530483457609039872",
      override:
        "https://discord.com/invite/kobe-s-court-house-of-highlights-530483457609039872",
      title: "Kobe Court House",
      description: "KobeBlackMamba",
      feed: "Kobe Court House",
    },
    {
      link: "https://discord.gg/MJXp6Q6",
      override: "https://discord.gg/MJXp6Q6",
      title: "Tripolar Bear",
      description: "Tripolar Bear",
      feed: "Tripolar Bear",
    },
    {
      link: "https://discord.gg/pohx",
      override: "https://discord.gg/pohx",
      title: "Kappa Klub",
      description: "Pohx",
      feed: "Kappa Klub",
    },
    {
      link: "https://discord.gg/XC7NGfx",
      override: "https://discord.gg/XC7NGfx",
      title: "The Tuna Pond",
      description: "CrouchingTuna",
      feed: "The Tuna Pond",
    },
    {
      link: "https://discord.gg/CBXYHxNpay",
      override: "https://discord.gg/CBXYHxNpay",
      title: "Sub Gang",
      description: "Subtractem",
      feed: "Sub Gang",
    },
    {
      link: "https://discord.gg/ZsWsrnqKtT",
      override: "https://discord.gg/ZsWsrnqKtT",
      title: "The Gambler's Den",
      description: "Omisid",
      feed: "The Gambler's Den",
    },
    {
      link: "https://discord.gg/R44bpra",
      override: "https://discord.gg/R44bpra",
      title: "BalorMage",
      description: "BalorMage",
      feed: "BalorMage",
    },
    {
      link: "https://discord.gg/Zizaran",
      override: "https://discord.gg/Zizaran",
      title: "Zizaran",
      description: "Zizaran",
      feed: "Zizaran",
    },
    {
      link: "https://discord.gg/steelmage",
      override: "https://discord.gg/steelmage",
      title: "Steelmage",
      description: "Steelmage",
      feed: "Steelmage",
    },
    {
      link: "https://discord.com/invite/uqqT4dc",
      override: "https://discord.com/invite/uqqT4dc",
      title: "Asmology",
      description: "Asmodeus",
      feed: "Asmology",
    },
    {
      link: "https://discord.gg/w22vWK3",
      override: "https://discord.gg/w22vWK3",
      title: "Forever Exiled",
      description: "Forever Exiled",
      feed: "Forever Exiled",
    },
    {
      link: "https://discord.com/invite/APemhwstwn",
      override: "https://discord.com/invite/APemhwstwn",
      title: "Fubgun's Server",
      description: "Fubgun",
      feed: "Fubgun's Server",
    },
    {
      link: "https://www.discord.gg/GhazzyTV",
      override: "https://www.discord.gg/GhazzyTV",
      title: "GhazzyTV",
      description: "GhazzyTV",
      feed: "GhazzyTV",
    },
  ];

  const media = [
    {
      link: "https://nessa.poekit.net/",
      override: "https://nessa.poekit.net/",
      title: "PoE Kit Nessa",
      description: "MTX Database",
      feed: "PoE Kit Nessa",
    },
    {
      link: "https://talesofwraeclast.com/",
      override: "https://talesofwraeclast.com/",
      title: "Tales of Wraeclast",
      description:
        "Fan Fiction; sharing captivating stories and artwork inspired by the world of Path of Exile and its iconic characters.",
      feed: "Tales of Wraeclast",
    },
    {
      link: "https://poeitem.crunchyintheory.com/create",
      override: "https://poeitem.crunchyintheory.com/create",
      title: "Path of Exile Item Creator",
      description: "Create your own Path of Exile items",
      feed: "Path of Exile Item Creator",
    },
    {
      link: "https://www.pathofexile.com/ladders",
      override: "https://www.pathofexile.com/ladders",
      title: "Official PoE Ladder",
      description: "Ladder from GGG",
      feed: "Official PoE Ladder",
    },
    {
      link: "https://poeladder.com/ladder",
      override: "https://poeladder.com/ladder",
      title: "PoE Ladder",
      description:
        "A ladder for tracking and ranking user unique collections in Path of Exile (PoE)",
      feed: "PatchBot",
    },
    {
      link: "https://patchbot.io/games/path-of-exile",
      override: "https://patchbot.io/games/path-of-exile",
      title: "PatchBot",
      description: "Discord Bot",
      feed: "PatchBot",
    },
    {
      link: "https://poe.fish/",
      override: "https://poe.fish/",
      title: "poe.fish",
      description:
        "Reliable source for information regarding fishing in Path of Exile",
      feed: "poe.fish",
    },
    {
      link: "https://www.poewiki.net",
      override: "https://www.poewiki.net",
      title: "Official PoE Wiki",
      description: "Wiki",
      feed: "Official PoE Wiki",
    },
    {
      link: "https://github.com/grindinggear",
      override: "https://github.com/grindinggear",
      title: "Grinding Gear Games Github",
      description: "GGG's Github",
      feed: "Grinding Gear Games Github",
    },
    {
      link: "https://oriath.net/Audio/",
      override: "https://oriath.net/Audio/",
      title: "Audio Index",
      description:
        "Annotated index of all voiceover audio contained in Path of Exile",
      feed: "Audio Index",
    },
    {
      link: "https://www.youtube.com/user/grindinggear",
      override: "https://www.youtube.com/user/grindinggear",
      title: "Official YouTube",
      description: "Videos",
      feed: "Official YouTube",
    },
    {
      link: "https://www.reddit.com/r/pathofexile",
      override: "https://www.reddit.com/r/pathofexile",
      title: "Reddit",
      description: "Community Forum",
      feed: "Reddit",
    },
    {
      link: "https://www.pathofexile.com",
      override: "https://www.pathofexile.com",
      title: "Official Site",
      description: "Path of Exile",
      feed: "Official Site",
    },
    {
      link: "https://twitter.com/pathofexile",
      override: "https://twitter.com/pathofexile",
      title: "Twitter",
      description: "Social Media",
      feed: "Twitter",
    },
    {
      link: "https://poepatchnotes.com/",
      override: "https://poepatchnotes.com/",
      title: "POE Patch notes",
      description: "Every Path of Exile patch note on one page",
      feed: "POE Patch notes",
    },
    {
      link: "https://www.grinding.zone/",
      override: "https://www.grinding.zone/",
      title: "Grinding.Zone",
      description: "PoE Links & Tools",
      feed: "Grinding.Zone",
    },
    {
      link: "https://www.gggtracker.com/",
      override: "https://www.gggtracker.com/",
      title: "GGG Tracker",
      description: "What's GGG staff posting?",
      feed: "GGG Tracker",
    },
    {
      link: "https://foreverexiled.com/",
      override: "https://foreverexiled.com/",
      title: "Forever Exiled",
      description: "Podcast",
      feed: "Forever Exiled",
    },
    {
      link: "https://fatedconnections.buzzsprout.com",
      override: "https://fatedconnections.buzzsprout.com",
      title: "Fated Connections",
      description: "Podcast",
      feed: "Fated Connections",
    },
    {
      link: "https://store.steampowered.com/app/238960/",
      override: "https://store.steampowered.com/app/238960/",
      title: "Steam",
      description: "Store Page",
      feed: "Steam",
    },
    {
      link: "https://steamdb.info/app/238960/",
      override: "https://steamdb.info/app/238960/",
      title: "SteamDB",
      description: "Steam Store Analytics",
      feed: "SteamDB",
    },
    {
      link: "https://steamcharts.com/app/238960",
      override: "https://steamcharts.com/app/238960",
      title: "Steam Charts",
      description: "Steam Store Analytics",
      feed: "Steam Charts",
    },
  ];

  const variantsOuter = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  useEffect(() => {
    async function fetchDataYT() {
      const yt = await getData(`videos/channels`);
      setYoutubeChannels(yt);
    }
    async function fetchDataTwitch() {
      let tw = await getData(`twitch/streamers`);
      tw = tw.filter((streamer) => streamer.viewer_count > 50);
      setTwitchChannels(tw);
    }

    fetchDataTwitch();
    fetchDataYT();
  }, []);

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      variants={variantsOuter}
      className={styles.container}
      key={`links`}
    >
      <h2 className={styles.dividerTitle}>Tools x{tools.length}</h2>
      <img src="/footer--alt.png" alt="" className={styles.dividerImage} />
      <div className={styles.outer}>
        {tools
          .sort(function (a, b) {
            let nameA = a.title.toUpperCase();
            let nameB = b.title.toUpperCase();
            if (nameA < nameB) {
              return -1; //nameA comes first
            }
            if (nameA > nameB) {
              return 1; // nameB comes first
            }
            return 0; // names must be equal
          })
          .map((link, index) => (
            <article key={`tools-${index}`} className={styles.article}>
              <div className={styles.glow} />
              <a href={link.link} target="_blank" rel="noopener noreferrer">
                <div className={styles.content}>
                  <div className={styles.contentInner}>
                    <div className={styles.meta}>
                      <img
                        src={`https://www.google.com/s2/favicons?domain=${
                          link.override ? link.override : link.link
                        }&sz=64`}
                        alt={"#"}
                        loading="lazy"
                      />
                      <div>
                        <h2 className={styles.title}>{link.title}</h2>
                        <div className={styles.description}>
                          <p>{link.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </article>
          ))}
      </div>

      <h2 className={styles.dividerTitle}>Builds x{builds.length}</h2>
      <img src="/footer--alt.png" alt="" className={styles.dividerImage} />
      <div className={styles.outer}>
        {builds
          .sort(function (a, b) {
            let nameA = a.title.toUpperCase();
            let nameB = b.title.toUpperCase();
            if (nameA < nameB) {
              return -1; //nameA comes first
            }
            if (nameA > nameB) {
              return 1; // nameB comes first
            }
            return 0; // names must be equal
          })
          .map((link, index) => (
            <article key={`builds-${index}`} className={styles.article}>
              <div className={styles.glow} />
              <a href={link.link} target="_blank" rel="noopener noreferrer">
                <div className={styles.content}>
                  <div className={styles.contentInner}>
                    <div className={styles.meta}>
                      <img
                        src={`https://www.google.com/s2/favicons?domain=${
                          link.override ? link.override : link.link
                        }&sz=64`}
                        alt={"#"}
                        loading="lazy"
                      />
                      <div>
                        <h2 className={styles.title}>{link.title}</h2>
                        <div className={styles.description}>
                          <p>{link.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </article>
          ))}
      </div>

      <h2 className={styles.dividerTitle}>Media x{media.length}</h2>
      <img src="/footer--alt.png" alt="" className={styles.dividerImage} />
      <div className={styles.outer}>
        {media
          .sort(function (a, b) {
            let nameA = a.title.toUpperCase();
            let nameB = b.title.toUpperCase();
            if (nameA < nameB) {
              return -1; //nameA comes first
            }
            if (nameA > nameB) {
              return 1; // nameB comes first
            }
            return 0; // names must be equal
          })
          .map((link, index) => (
            <article key={`media-${index}`} className={styles.article}>
              <div className={styles.glow} />
              <a href={link.link} target="_blank" rel="noopener noreferrer">
                <div className={styles.content}>
                  <div className={styles.contentInner}>
                    <div className={styles.meta}>
                      <img
                        src={`https://www.google.com/s2/favicons?domain=${
                          link.override ? link.override : link.link
                        }&sz=64`}
                        alt={"#"}
                        loading="lazy"
                      />
                      <div>
                        <h2 className={styles.title}>{link.title}</h2>
                        <div className={styles.description}>
                          <p>{link.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </article>
          ))}
      </div>

      <h2 className={styles.dividerTitle}>Discords x{discords.length}</h2>
      <img src="/footer--alt.png" alt="" className={styles.dividerImage} />
      <div className={styles.outer}>
        {discords
          .sort(function (a, b) {
            let nameA = a.title.toUpperCase();
            let nameB = b.title.toUpperCase();
            if (nameA < nameB) {
              return -1; //nameA comes first
            }
            if (nameA > nameB) {
              return 1; // nameB comes first
            }
            return 0; // names must be equal
          })
          .map((link, index) => (
            <article key={`discords-${index}`} className={styles.article}>
              <div className={styles.glow} />
              <a href={link.link} target="_blank" rel="noopener noreferrer">
                <div className={styles.content}>
                  <div className={styles.contentInner}>
                    <div className={styles.meta}>
                      <img
                        src={`https://www.google.com/s2/favicons?domain=${
                          link.override ? link.override : link.link
                        }&sz=64`}
                        alt={"#"}
                        loading="lazy"
                      />
                      <div>
                        <h2 className={styles.title}>{link.title}</h2>
                        <div className={styles.description}>
                          <p>{link.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </article>
          ))}
      </div>

      {twitch_channels.length > 0 && (
        <>
          <h2 className={styles.dividerTitle}>
            Popular Twitch Streamers x{twitch_channels.length}
          </h2>
          <img src="/footer--alt.png" alt="" className={styles.dividerImage} />
          <div className={styles.outer}>
            {twitch_channels.map((link, index) => (
              <article
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                // exit="exit"
                variants={variants}
                transition={{
                  duration: 0.333,
                  ease: "easeInOut",
                  // delay: 0.1,
                }}
                key={`youtube-${index}`}
                className={styles.article}
              >
                <div className={styles.glow} />
                <a
                  href={`https://twitch.tv/${link.user_login ? link.user_login : link.user_name}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className={styles.content}>
                    <div className={styles.contentInner}>
                      <div className={styles.meta}>
                        <img
                          src={link.avatar}
                          alt={link.channel}
                          loading="lazy"
                          width={42}
                          height={42}
                        />
                        <div>
                          <h2 className={styles.title}>{link.user_name}</h2>
                          <div className={styles.description}>
                            <p>{link.title.substring(0, 72)}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </article>
            ))}
          </div>
        </>
      )}

      {youtube_channels.length > 0 && (
        <>
          <h2 className={styles.dividerTitle}>
            YouTube x{youtube_channels.length}
          </h2>
          <img src="/footer--alt.png" alt="" className={styles.dividerImage} />
          <div className={styles.outer}>
            {youtube_channels
              .sort(function (a, b) {
                let nameA = a.channel.toUpperCase();
                let nameB = b.channel.toUpperCase();
                if (nameA < nameB) {
                  return -1; //nameA comes first
                }
                if (nameA > nameB) {
                  return 1; // nameB comes first
                }
                return 0; // names must be equal
              })
              .map((link, index) => (
                <article
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  // exit="exit"
                  variants={variants}
                  transition={{
                    duration: 0.333,
                    ease: "easeInOut",
                    // delay: 0.1,
                  }}
                  key={`youtube-${index}`}
                  className={styles.article}
                >
                  <div className={styles.glow} />
                  <a
                    href={`https://www.youtube.com/channel/${link.channel_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className={styles.content}>
                      <div className={styles.contentInner}>
                        <div className={styles.meta}>
                          <img
                            src={`https://startyparty.nyc3.digitaloceanspaces.com/channels/${link.channel_id}.webp`}
                            alt={link.channel}
                            loading="lazy"
                            width={42}
                            height={42}
                          />
                          <div>
                            <h2 className={styles.title}>{link.channel}</h2>
                            {/* <div className={styles.description}>
                            <p>{link.description}</p>
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </article>
              ))}
          </div>
        </>
      )}
    </motion.div>
  );
}
