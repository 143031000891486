import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

import { getData } from "../../utils/data";

import Streamer from "./streamer";
import { variants } from "../../utils/variants";

import styles from "../music/music.module.scss";

export default function Streamers() {

  const [twitch_channels, setTwitchChannels] = useState([]);

  useEffect(() => {
    async function fetchDataTwitch() {
      const tw = await getData(`twitch/streamers`);
      setTwitchChannels(tw);
    }
    fetchDataTwitch();
  }, []);

  return (
    <>
      <motion.div
        key={twitch_channels}
        initial="hidden"
        animate="visible"
        variants={variants}
        className={`${styles.grid} ${styles.gridPlaylist}`}
      >
        <div className={`${styles.musicRow}`}>
          <h2 className={styles.dividerTitle}>
            All Twitch Streamers x{twitch_channels.length}
          </h2>
          <img src="/footer--alt.png" alt="" className={styles.dividerImage} />

          {twitch_channels.map((post, index) => (
            <Streamer
              key={index}
              data={post}
            />
          ))}
        </div>
      </motion.div>
    </>
  );
}
