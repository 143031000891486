import React, { useState } from 'react';
import { motion } from 'framer-motion';

// import { ReactComponent as IconSearch } from '../../icons/icon__search.svg';
// import { ReactComponent as IconClose } from '../../icons/icon__close.svg';

import styles from './search.module.scss';

export default function Search(props) {

  const [search, setSearch] = useState('');

  const variants = {
    hidden: { opacity: 0, marginTop: -10 },
    visible: { opacity: 1, marginTop: 0},
    exit: { opacity: 0, marginTop: 10 },
    // delay: 0.15,
  };

  const handleKeyPress = (event) => {
    if (event.shiftKey && event.key === 'Enter') {
      window.open(`https://www.poewiki.net/index.php?search=${search}&title=Special%3ASearch&fulltext=Search`, '_blank');
      props.callback(false);
    } else {
      if (event.key === 'Enter') {
        if (search === '') return;
        if (search.length < 3) return;
        props.searchFeed(search)
        props.callback(false);
      }
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    props.callback(false);
    props.searchFeed(false);
  }

  const validateInput = (input) => {
    const regex = /[^a-zA-Z0-9 ]/g;
    return input.replace(regex, '');
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={variants}
      transition={{
        duration: 0.333,
        ease: "easeInOut",
      }}
      key={props.data}
      className={styles.search}
    >
      <img
        className={styles.boxClose}
        onClick={(e) => handleClose(e)}
        src="/icon__close.png"
        alt="Close"
        width={32}
        height={32}
      />
      <div className={styles.box}>
        <div className={styles.boxInner}>
          <input
            type="text"
            placeholder="Search"
            onChange={(e) => setSearch(validateInput(e.target.value))}
            value={search}
            onKeyDown={handleKeyPress}
            focus="true"
            autoFocus={true}
          />
          {/* <IconSearch className={styles.searchIcon} /> */}
        </div>
      </div>
      <div className={styles.boxNote}>
        <p><strong>Shift + Enter</strong> to search PoE Wiki</p>
      </div>
    </motion.div>
  );
}