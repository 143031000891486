// fetch request to get data from the API
export const getData = async (url, page=false) => {
  const prefix = 'https://sp.marko.tech/pathofexile/';
  let new_url = '';
  if (page) {
    new_url = prefix + url + '?page=' + page;
  } else {
    new_url = prefix + url;
  }
  const response = await fetch(new_url);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return await response.json();
}
