import React, { useEffect, useState } from 'react';
import YouTube from 'react-youtube';
import { motion, AnimatePresence } from 'framer-motion';

import RelativeTimestamp from '../../utils/timeago';
import { cleanUpChannelName, timeStringToUnixSeconds, checkFallbackImage } from "../../utils/utility";
import { variants } from '../../utils/variants';

import { ReactComponent as IconPodcasts } from '../../icons/icon__podcasts.svg';
import { ReactComponent as IconYouTube } from '../../icons/icon__youtube.svg';
import { ReactComponent as IconNews } from '../../icons/icon__news.svg';
import { ReactComponent as IconMusic } from '../../icons/icon__music.svg';
import { ReactComponent as IconMetal } from '../../icons/icon__metal.svg';
import { ReactComponent as IconVibes } from '../../icons/icon__vibes.svg';
import { ReactComponent as IconLofi } from '../../icons/icon__lofi.svg';
import { ReactComponent as IconClose } from '../../icons/icon__close.svg';
import { ReactComponent as IconHipHop } from '../../icons/icon__hiphop.svg';

import styles from './search.module.scss';

export default function Article(props) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoReady, setVideoReady] = useState(false);

  const titleMap = {
    'news': 'article',
    'video': 'video',
    'videos': 'video',
    'youtube': 'video',
    'search': 'results',
    'twitch': 'streamer',
    'games': 'game',
    'music': 'music',
    'github': 'events',
    'poe': 'character',
    'podcasts': 'podcast',
    'podcast': 'podcast',
    'metal': 'music',
    'hiphop': 'music',
    'vibes': 'music',
    'lofi': 'music'
  };

  const handleChannelClick = (e, channel) => {
    e.preventDefault();

    console.log(props.data.type)

    if (props.data.type === 'music') {
      props.musicNav(channel);
    }

    if (props.data.type === 'podcast') {
      props.podNav(channel);
    }

    if (props.data.type === 'youtube') {
      props.vidNav(channel);
    }

    if (props.data.type === 'news') {
      props.pubNav(channel);
    }
  }

  const sendDataToParent = (e) => {
    e.preventDefault();

    if (props.data.type === 'news') {
      window.open(props.data.link, '_blank');
      return;
    }

    if (props.data.type === 'music' ||
        props.data.type === 'podcast'
    ) {
      if (e.shiftKey) {
        props.musicNavPlaylist(props.data);
      } else {
        props.sendDataToParent(props.data);
      }
    }

    if (props.data.type === 'youtube') {
        startUpVideo();
        props.sendDataToParent(false);
        props.musicNav(false);
    }

    return;
  };

  const startUpVideo = () => {
    setIsPlaying(!isPlaying);
  };

  const handleVideoReady = (event) => {
    setVideoReady(true);
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        setIsPlaying(false);
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };
    if (isPlaying) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isPlaying]);

  if (props.data.title && props.data.title.toLowerCase().includes('[private video]')) {
    return null;
  }

  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 1,
      color: 'white',
      playsinline: 1,
      // controls: 0,
    },
  };

  const variantsPreview = {
    hidden: { opacity: 0 },
    visible: { opacity: 1},
    exit: { opacity: 0 },
    delay: 0.666,
  };

  const postTypeConvert = (type) => {
    const typeMap = {
      'lofi': <IconLofi className={styles.icon} />,
      'vibes': <IconVibes className={styles.icon} />,
      'hiphop': <IconHipHop className={styles.icon} />,
      'metal': <IconMetal className={styles.icon} />,
      'podcast': <IconPodcasts className={styles.icon} />,
      'youtube': <IconYouTube className={styles.icon} />,
      'videos': <IconYouTube className={styles.icon} />,
      'news': <IconNews className={styles.icon} />,
      'music': <IconMusic className={styles.icon} />,
    }
    return typeMap[type];
  }

  if (!props.data) return;

  return (
    <motion.article
      variants={variants}
      initial="hidden"
      whileInView="visible"
      className={
        props.fullscreen ? `${styles.outer} ${styles.fullscreen}` : styles.outer
      }
    >
      <div className={styles.glow} />
      <AnimatePresence>
        <motion.div
          key={isPlaying}
          initial="hidden"
          animate={videoReady ? "visible" : "hidden"}
          exit="exit"
          variants={variantsPreview}
          transition={{
            duration: 1,
            ease: [0.43, 0.13, 0.23, 0.96],
          }}
        >
          {isPlaying && (
            <>
              <IconClose
                className={styles.close}
                onClick={(e) => startUpVideo()}
              />
              <YouTube
                videoId={props.data.id}
                className={`${styles.youtube} ${
                  isPlaying ? styles.youtubeShown : ""
                }`}
                onReady={handleVideoReady}
                onEnd={(e) => startUpVideo()}
                opts={opts}
              />
            </>
          )}
        </motion.div>
      </AnimatePresence>
      <a
        href={props.data.link}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.article}
      >
        <div className={styles.image} onClick={(e) => sendDataToParent(e)}>
          <img
            src={checkFallbackImage(props.data.image)}
            alt={titleMap[props.data.type]}
            loading="lazy"
          />
          {props.data.duration && (
            <span className={styles.songDuration}>
              <span>
                {Number.isInteger(props.data.duration)
                  ? timeStringToUnixSeconds(props.data.duration)
                  : props.data.duration}
              </span>
            </span>
          )}
        </div>
        <div className={styles.content}>
          <div className={styles.meta}>
            <div className={`${styles.metaInner} ${styles.metaInnerType}`}>
              <span
                className={`${styles.type} ${
                  props.fullscreen ? styles.typeFullscreen : ""
                }`}
                title={titleMap[props.data.type]}
              >
                {postTypeConvert(props.data.type)}
              </span>
              <div className={styles.metaInner}>
                {props.data.type !== "youtube" &&
                  props.data.type !== "music" &&
                  props.data.type !== "podcast" && (
                    <img
                      src={`https://www.google.com/s2/favicons?domain=${props.data.link}&sz=64`}
                      alt={props.data.link.substring(0, 12)}
                      width={22}
                      height={22}
                      loading="lazy"
                    />
                  )}
                {props.data.type === "youtube" && (
                  <img
                    src={`https://startyparty.nyc3.digitaloceanspaces.com/channels/${props.data.channel_id}.webp`}
                    alt={props.data.link.substring(0, 12)}
                    width={22}
                    height={22}
                    loading="lazy"
                  />
                )}
                {props.data.feed && (
                  <span
                    className={styles.channel}
                    onClick={(e) => {
                      if (!props.data.feed) return;
                      handleChannelClick(e, props.data.feed);
                    }}
                  >
                    {cleanUpChannelName(props.data.feed)}
                  </span>
                )}
                {props.data.channel && (
                  <span
                    className={styles.channel}
                    onClick={(e) => {
                      if (!props.data.channel) return;
                      handleChannelClick(e, props.data.channel);
                    }}
                  >
                    {cleanUpChannelName(props.data.channel)}
                  </span>
                )}
              </div>
              {/* <div className={`${styles.tags} ${styles.tagsShown}`}>
                <span className={styles.tag}>{props.data.type}</span>
              </div> */}
            </div>
            {props.data.pub && (
              <div
                className={styles.duration}
                onClick={(e) => sendDataToParent(e)}
              >
                <RelativeTimestamp timestamp={props.data.pub} />
              </div>
            )}
          </div>
          <div
            className={styles.contentInner}
            onClick={(e) => sendDataToParent(e)}
          >
            {props.data.title && (
              <h3 className={styles.title}>{props.data.title}</h3>
            )}
            {props.data.name && (
              <h3 className={styles.title}>{props.data.name}</h3>
            )}
            {props.data.description && (
              <div className={styles.description}>
                <p>{props.data.description}</p>
              </div>
            )}
            {props.data.short_description && (
              <div className={styles.description}>
                <p>{props.data.short_description}</p>
              </div>
            )}
          </div>
          {/* {props.data.tags && Array.isArray(props.data.tags) && (
            <div className={styles.tags}>
              {props.data.tags.slice(0, 6).map((tag, index) => {
                if (typeof tag === "object") {
                  return null;
                }

                return (
                  <span
                    key={`tag-${tag}-${index}`}
                    className={styles.tag}
                    onClick={(e) => handleClick(e, tag)}
                  >
                    {tag.length > 24 ? tag.slice(0, 24) + "..." : tag}
                  </span>
                );
              })}
            </div>
          )} */}
        </div>
      </a>
    </motion.article>
  );
}