import React, { useEffect, useState } from 'react';
import styles from './music.module.scss';
import { motion } from 'framer-motion';

import { timeStringToUnixSeconds, cleanUpChannelName } from '../../utils/utility';
import { variants } from '../../utils/variants';

import { ReactComponent as IconTime } from '../../icons/icon__time.svg';
import { ReactComponent as IconAdd } from '../../icons/icon__add.svg';
import { ReactComponent as IconClose } from '../../icons/icon__close.svg';

export default function Music(props) {
  const [closed, setClosed] = useState(false);

  const { title, duration, channel, metadata } = props.data;
  const channelName = channel ? channel.replace(/ - Topic$/, '') : '';

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Meta') {
        e.preventDefault();
        setClosed(true);
      }
    };

    const handleKeyUp = (e) => {
      if (e.key === 'Meta') {
        setClosed(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  if (!props.data) return null;


  const sendDataToParent = (e) => {
    e.preventDefault();
    if (e.shiftKey) {
      props.musicNavPlaylist(props.data);
      return;
    }
    if (props.playlistCheck) {
      if (e.metaKey) {
        props.playlistRemoveNav(props.data);
        return;
      }
    }

    if (e.target.className === styles.trigger) {
      props.musicNavPlaylist(props.data);
    } else if (e.target.className === styles.musicClose) {
      if (props.playlistCheck) {
        props.playlistRemoveNav(props.data);
      }
    } else if (e.target.className === styles.tag) {
      props.musicTagNav(e.target.dataset.tag);
    } else if (e.target.className === styles.channel) {
      const pass = metadata && metadata.artist ? metadata.artist : channel;
      props.musicNav(pass);
    } else {
      props.sendDataToParent(props.data);
    }
  };

  return (
    <motion.article
      initial="hidden"
      whileInView="visible"
      variants={variants}
      className={
        props.fullscreen ? `${styles.music} ${styles.fullscreen}` : styles.music
      }
      onClick={(e) => sendDataToParent(e)}
    >
      <div className={styles.glow} />
      <div className={styles.content}>
        <div className={`${styles.image}`}>
          <img
            className={styles.trigger}
            src={props.data.image}
            alt={props.data.title}
            loading="lazy"
          />
          {props.playlistCheck && (
            <>
              <div
                className={`${styles.musicAdd} ${closed ? styles.musicCloseHidden : ""
                  }`}
              >
                <IconAdd className={`${styles.imageAdd} ${styles.trigger}`} />
              </div>
              <div
                className={`${styles.musicClose} ${closed ? styles.musicCloseShown : ""
                  }`}
              >
                <IconClose
                  className={`${styles.imageClose} ${styles.triggerClose}`}
                />
              </div>
            </>
          )}
          {!props.playlistCheck && (
            <div className={`${styles.musicAdd}`}>
              <IconAdd className={`${styles.imageAdd} ${styles.trigger}`} />
            </div>
          )}
        </div>
        <div className={styles.meta}>
          {metadata && <h3 className={styles.title}>{metadata.title}</h3>}
          {!metadata && <h3 className={styles.title}>{title}</h3>}
          <span className={styles.channel}>
            {metadata && cleanUpChannelName(metadata.artist)}
            {!metadata && cleanUpChannelName(channelName)}
          </span>
        </div>
        <span className={styles.duration}>
          <span>
            <IconTime />
            {timeStringToUnixSeconds(duration)}
          </span>
        </span>
      </div>
    </motion.article>
  );
}