import React, { useRef } from "react";
import { motion } from "framer-motion";

import styles from "./player.module.scss";

const Marquee = ({text}) => {
  const ref = useRef(null);
  const width = ref.current ? ref.current.offsetWidth - (ref.current.offsetWidth / 3) : 0;

  const vars = {
    animate: {
      x: [0, -width],
      transition: {
        x: {
          repeat: Infinity,
          repeatType: "loop",
          duration: width * 0.033,
          ease: "linear",
        },
      },
    },
  };

  return (
    <span className={styles.marquee}>
      <motion.span
        className={styles.track}
        variants={vars}
        animate="animate"
        ref={ref}
      >
        {text}{" "}{text}{" "}{text}{" "}
      </motion.span>
    </span>
  );
};

export default Marquee;
