import styles from '../music/music.module.scss';
import { motion } from 'framer-motion';

// import {
//   cleanUpChannelName,
//   formatNumberWithCommas,
// } from "../../utils/utility";
import RelativeTimestamp from '../../utils/timeago';
import { variants } from '../../utils/variants';

export default function Streamer(props) {
  const {
    user_login,
    user_name,
    title,
    avatar,
    started_at,
    // viewer_count,
    status,
  } = props.data;

  const start = new Date(started_at);
  const unixTimestamp = start.getTime() / 1000;

  const handleClick = (e) => {
    e.preventDefault();
    // open href in new tab
    window.open(`https://twitch.tv/${user_login ? user_login : user_name}`);
    return false;
  }

  if (!props.data) return null;

  return (
    <motion.article
      initial="hidden"
      whileInView="visible"
      variants={variants}
      className={
        props.fullscreen ? `${styles.music} ${styles.fullscreen}` : styles.music
      }
      onClick={handleClick}
    >
      <div className={styles.glow} />
      <div className={styles.content}>
        <div className={`${styles.image}`}>
          <img
            className={styles.trigger}
            src={avatar}
            alt={props.data.title}
            loading="lazy"
          />
        </div>
        <div className={`${styles.meta} ${styles.metaTwitch}`}>
          <div className={styles.channelTwitch}>
            <span className={styles.channel}>{user_name}</span>
          </div>
          <p className={styles.titleTwitch}>{title.trim()}</p>
        </div>
        <span className={styles.duration}>
          <span>
            {unixTimestamp > 0 && (
              <RelativeTimestamp timestamp={unixTimestamp} />
            )}
            {!unixTimestamp && status === "offline" && <span>offline</span>}
          </span>
        </span>
      </div>
    </motion.article>
  );
}