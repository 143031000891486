export function checkFallbackImage(image) {
  if (image === 'fallback.png' || image === '/fallback.png') {
    const randomNumber = Math.floor(Math.random() * 15) + 1;
    return `/fallback-${randomNumber}.png`;
  } else {
    return image;
  }
}

export function timeStringToUnixSeconds(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = hours > 0 ? hours.toString().padStart(1, '0') : '';
  let formattedMinutes = Math.floor(minutes).toString().padStart(2, '0');
  const formattedSeconds = Math.floor(remainingSeconds).toString().padStart(2, '0');

  // Remove leading zero from minutes if hours is 0 and minutes is less than 10
  if (formattedHours === '' && minutes < 10) {
    formattedMinutes = formattedMinutes.substring(1);
  }

  return `${formattedHours !== '' ? formattedHours + ':' : ''}${formattedMinutes}:${formattedSeconds}`;
}

export function formatUnixTimestamp(timestamp) {
  const milliseconds = timestamp * 1000;
  const date = new Date(milliseconds);

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const dayOfWeek = dayNames[date.getDay()];
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const hour = date.getHours();

  const time = `${hour % 12 === 0 ? 12 : hour % 12}${hour >= 12 ? 'pm' : 'am'}`;
  const formattedString = `${dayOfWeek}, ${month} ${day} @ ${time}`;

  return formattedString;
}

export function formatNumberWithSuffix(number) {
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1) + 'M';
  } else if (number >= 1000) {
    return (number / 1000).toFixed(0) + 'k';
  } else {
    return number;
  }
}

export const formatNumberWithCommas = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const cleanUpChannelName = (channel) => {
  channel = channel.replace(/ - Topic$/, '');
  channel = channel.replace(/ Official$/, '');
  channel = channel.replace(/ Oficial$/, '');
  channel = channel.replace(/ Intl$/, '');
  channel = channel.replace(/VEVO$/, '');
  channel = channel.replace(/ - Chill Beats$/, '');
  channel = channel.replace(/ (Japan)$/, '');
  channel = channel.replace(/VideoZone$/, '');
  channel = channel.replace(/ Music$/, '');
  channel = channel.replace(/Lyrics$/, '');
  channel = channel.replace(/music$/, '');
  channel = channel.replace(/Music$/, '');
  channel = channel.replace(/TV$/, '');
  return channel;
}

export const getPercentDiff = (a, b) => {
  const numA = parseInt(a, 10);
  const numB = parseInt(b, 10);

  if (isNaN(numA) || isNaN(numB)) {
    throw new Error("One or both inputs are not valid numbers.");
  }

  if (numA === numB) return 0;

  const difference = Math.abs(numA - numB);
  const average = (numA + numB) / 2;
  const percentageDifference = (difference / average) * 100;

  return parseFloat(percentageDifference.toFixed(3));
}

export const getDifference = (a, b) => {
  const numA = parseInt(a, 10);
  const numB = parseInt(b, 10);

  if (isNaN(numA) || isNaN(numB)) {
    throw new Error("One or both inputs are not valid numbers.");
  }

  if (numA === numB) return 0;

  const difference = Math.abs(numA - numB);
  return formatNumberWithCommas(difference);
};

export function timeAgo(unixTimestamp) {
  if (!unixTimestamp || unixTimestamp < 0) return null;

  const now = new Date().getTime();
  const secondsPast = (now - unixTimestamp * 1000) / 1000;

  if (secondsPast < 60) {
    return "just now";
  }

  if (secondsPast < 3600) {
    const minutes = Math.floor(secondsPast / 60);
    return `${minutes}m`;
  }

  if (secondsPast < 86400) {
    const hours = Math.floor(secondsPast / 3600);
    return `${hours}h`;
  }

  if (secondsPast < 604800) {
    const days = Math.floor(secondsPast / 86400);
    if (days === 1) {
      return "yesterday";
    }
    return `${days}d`;
  }

  if (secondsPast < 31536000) {
    const weeks = Math.floor(secondsPast / 604800);
    if (weeks === 1) {
      return "1w";
    }
    return `${weeks}w`;
  }

  const years = Math.floor(secondsPast / 31536000);
  return `${years}y`;
}

export function timeAgoInSeconds(unixTimestamp) {
  if (!unixTimestamp || unixTimestamp < 0) return null;

  const now = new Date().getTime();
  const secondsPast = Math.floor((now - unixTimestamp * 1000) / 1000);

  return `${secondsPast}s ago`;
}
