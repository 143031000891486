import { motion } from 'framer-motion';
import styles from './podcast.module.scss';
import RelativeTimestamp from '../../utils/timeago';
import { timeStringToUnixSeconds } from '../../utils/utility';
import { variants } from '../../utils/variants';

export default function Podcast(props) {
  if (!props.data) return null;
  // let dura = null;

  let { title, image, duration, feed, pub, description } = props.data;
  duration = timeStringToUnixSeconds(duration);

  const handleAudioPlay = (e) => {
    e.preventDefault();
    props.sendDataToParent(props.data);
  };

  const handleChannelClick = (e) => {
    e.preventDefault();
    props.podNav(feed);
  }

  return (
    <motion.article
      initial="hidden"
      whileInView="visible"
      variants={variants}
      className={props.fullscreen ? `${styles.podcast} ${styles.fullscreen}` : styles.podcast}
    >
      <div className={styles.glow} />
      <div
        className={styles.image}
        onClick={(e) => handleAudioPlay(e)}
      >
        <img
          src={image}
          alt={title}
          loading='lazy'
        />
        {duration && (
          <div className={styles.dura}>
            <span className={styles.date}>
              {duration}
            </span>
            {/* {dura && (
              <span className={styles.dateDura}>
                {dura}
              </span>
            )} */}
          </div>
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.meta}>
          <span className={styles.channel} onClick={(e) => handleChannelClick(e)}>{feed}</span>
          <span className={styles.duration} onClick={(e) => handleAudioPlay(e)}>
            <RelativeTimestamp timestamp={pub} />
          </span>
        </div>
        <h3 className={styles.title} onClick={(e) => handleAudioPlay(e)}>{title}</h3>
        <div className={styles.description} onClick={(e) => handleAudioPlay(e)}>
          <p>{description}</p>
        </div>
      </div>
    </motion.article>
  );
}