import { motion } from 'framer-motion';

import styles from './shortcuts.module.scss';
import { variants } from '../../utils/variants';
import { ReactComponent as IconSearch } from "../../icons/icon__search.svg";
// import { ReactComponent as IconInfo } from "../../icons/icon__info.svg";
import { ReactComponent as IconFullscreen } from "../../icons/icon__fullscreen.svg";
import { ReactComponent as IconShrink } from "../../icons/icon__shrink.svg";
import { ReactComponent as IconKeyboard } from "../../icons/icon__keyboard.svg";
// import { ReactComponent as IconPathOfExile } from "../../icons/icon__poe.svg";
import { ReactComponent as IconEscape } from "../../icons/icon__escape.svg";
import { ReactComponent as IconPlay } from "../../icons/icon__play.svg";
import { ReactComponent as IconVolumeUp } from "../../icons/icon__volume-up.svg";
import { ReactComponent as IconVolumeDown } from "../../icons/icon__volume-down.svg";
import { ReactComponent as IconVolumeMute } from "../../icons/icon__volume-mute.svg";
import { ReactComponent as IconRewind } from "../../icons/icon__rewind.svg";
import { ReactComponent as IconFastForward } from "../../icons/icon__ff.svg";
import { ReactComponent as IconMusic } from "../../icons/icon__music.svg";
import { ReactComponent as IconBack } from "../../icons/icon__back.svg";
import { ReactComponent as IconStop } from "../../icons/icon__stop.svg";
import { ReactComponent as IconSkip } from "../../icons/icon__skip.svg";
import { ReactComponent as IconUp } from "../../icons/icon__up.svg";
import { ReactComponent as IconDown } from "../../icons/icon__down.svg";
import { ReactComponent as IconPlaylist } from "../../icons/icon__playlist.svg";
// import { ReactComponent as IconPolitics } from "../../icons/icon__politics.svg";
// import RelativeTimestamp from '../../utils/timeago';

export default function Shortcuts() {

  const shortcuts = [
    {
      title: "Close Modals & Popups",
      description: "Videos, Search, Reader views",
      shortcut: "escape",
      icon: <IconEscape />,
    },
    {
      title: "Search",
      description:
        "Find Anything; searches all content. Shift + Enter for Google. Command + Enter for PoeWiki.",
      shortcut: "/",
      icon: <IconSearch />,
    },
    {
      title: "Back",
      description: "Go Back One Page",
      shortcut: "backspace",
      icon: <IconBack />,
    },
    {
      title: "Fullscreen Size Mode",
      description: "Larger Container",
      shortcut: "]",
      icon: <IconFullscreen />,
    },
    {
      title: "Default Size Mode",
      description: "Shrunken, Default Container",
      shortcut: "[",
      icon: <IconShrink />,
    },
    {
      title: "Next Page",
      description: "Navigate to Next Page",
      shortcut: "right arrow",
      icon: <IconSkip />,
    },
    {
      title: "Previous Page",
      description: "Navigate to Previous Page",
      shortcut: "left arrow",
      icon: <IconBack />,
    },
    {
      title: "Scroll Page Up",
      description: "Move Container View Up 1/3rd of Viewport",
      shortcut: "up arrow",
      icon: <IconUp />,
    },
    {
      title: "Scroll Page Down",
      description: "Move Container View Down 1/3rd of Viewport",
      shortcut: "up arrow",
      icon: <IconDown />,
    },
    {
      title: "Shortcuts",
      description: "List of Commands; this page",
      shortcut: "i",
      icon: <IconKeyboard />,
    }
  ];

  const media = [
    {
      title: "Play / Pause Media",
      description: "Video, Music & Podcasts",
      shortcut: "shift + space",
      icon: <IconPlay />,
    },
    {
      title: "Volume Up Media",
      description: "Video, Music & Podcasts by 10%",
      shortcut: "shift + up",
      icon: <IconVolumeUp />,
    },
    {
      title: "Volume Down Media",
      description: "Video, Music & Podcasts by 10%",
      shortcut: "shift + down",
      icon: <IconVolumeDown />,
    },
    {
      title: "Volume Mute Toggle Media",
      description: "Video, Music & Podcasts Volume to 0",
      shortcut: "shift + m",
      icon: <IconVolumeMute />,
    },
    {
      title: "Rewind Media",
      description: "Video, Music & Podcasts by 5 seconds",
      shortcut: "shift + left",
      icon: <IconRewind />,
    },
    {
      title: "Fast Forward Media",
      description: "Video, Music & Podcasts by 15 seconds",
      shortcut: "shift + right",
      icon: <IconFastForward />,
    },
    {
      title: "Stop/Skip Media",
      description: "Video, Music & Podcasts End & Close or Skip to Next Song if Playlist is Active",
      shortcut: "shift + s",
      icon: <IconStop />,
    },
    {
      title: "Random Song",
      description: "Plays Random Song",
      shortcut: "\\",
      icon: <IconMusic />,
    },
    {
      title: "Add to Playlist",
      description: "Queue Song to Playlist",
      shortcut: "shift + click song",
      icon: <IconPlaylist />,
    },
    {
      title: "Share Now Playing",
      description: "Copies Current Song URL to Clipboard",
      shortcut: "shift + click media player",
      icon: <IconMusic />,
    },
  ];

  const variantsOuter = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      variants={variantsOuter}
      className={styles.container}
      key={`links`}
    >
      <h3 className={styles.dividerTitle}>navigation</h3>
      <img src="/footer--alt.png" alt="" className={styles.dividerImage} />
      <div className={styles.outer}>
        {shortcuts.map((shortcut, index) => (
          <motion.article
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            // exit="exit"
            variants={variants}
            transition={{
              duration: 0.333,
              ease: "easeInOut",
              // delay: 0.1,
            }}
            key={`shortcuts-${index}`}
            className={styles.article}
          >
            <div className={styles.glow} />
            <div className={styles.content}>
              <div className={styles.meta}>
                {shortcut.icon}
                <div>
                  <h3 className={styles.title}>{shortcut.title}</h3>
                  <div className={styles.description}>
                    <p>{shortcut.description}</p>
                    <pre className={styles.shortcut}>
                      <code>{shortcut.shortcut}</code>
                    </pre>
                  </div>
                </div>
              </div>
            </div>
          </motion.article>
        ))}
      </div>

      <h3 className={styles.dividerTitle}>media controls</h3>
      <img src="/footer--alt.png" alt="" className={styles.dividerImage} />
      <div className={styles.outer}>
        {media.map((shortcut, index) => (
          <motion.article
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            // exit="exit"
            variants={variants}
            transition={{
              duration: 0.333,
              ease: "easeInOut",
              // delay: 0.1,
            }}
            key={`shortcuts-${index}`}
            className={styles.article}
          >
            <div className={styles.glow} />
            <div className={styles.content}>
              <div className={styles.meta}>
                {shortcut.icon}
                <div>
                  <h3 className={styles.title}>{shortcut.title}</h3>
                  <div className={styles.description}>
                    <p>{shortcut.description}</p>
                    <pre className={styles.shortcut}>
                      <code>{shortcut.shortcut}</code>
                    </pre>
                  </div>
                </div>
              </div>
            </div>
          </motion.article>
        ))}
      </div>

    </motion.div>
  );
}