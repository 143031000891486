import React, { useState, useEffect } from 'react';
import styles from './poe.module.scss';
import { motion, AnimatePresence } from 'framer-motion';
import YouTube from 'react-youtube';
// import RelativeTimestamp from '../../utils/timeago';
import { formatNumberWithSuffix } from '../../utils/utility';
import { variants } from '../../utils/variants';

import { ReactComponent as IconPoE } from '../../icons/icon__sword.svg';
import { ReactComponent as IconNinja } from '../../icons/icon__ninja.svg';
// import { ReactComponent as IconGear } from '../../icons/icon__gear.svg';
import { ReactComponent as IconPoEStack } from '../../icons/icon__poestack.svg';
import { ReactComponent as IconClose } from '../../icons/icon__close.svg';
import { ReactComponent as IconYouTube } from '../../icons/icon__youtube.svg';
import { ReactComponent as IconPOE } from '../../icons/icon__poe.svg';

export default function PathOfExile(props) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoReady, setVideoReady] = useState(false);

  const {
    title,
    image,
    dps,
    // pub,
    level,
    // description,
    league_class,
    league_date,
    league_name,
    league_number,
    challenges,
    link,
    poeninja,
    poeofficial,
    poestack,
    video_id
  } = props.data;

  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 1,
      color: 'white',
      playsinline: 1,
      // controls: 0,
    },
  };

  const variantsYT = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 },
    delay: 1,
  };

  const startUpVideo = (e) => {
    e.preventDefault();
    setIsPlaying(!isPlaying);
  };

  const handleVideoReady = (event) => {
    setVideoReady(true);
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        setIsPlaying(false);
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };
    if (isPlaying) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isPlaying]);

  return (
    <motion.article
      initial="hidden"
      whileInView="visible"
      variants={variants}
      className={props.fullscreen ? `${styles.poe} ${styles.fullscreen}` : styles.poe}
    >
      {video_id && (
        <AnimatePresence>
          <motion.div
            key={isPlaying}
            initial="hidden"
            animate={videoReady ? "visible" : "hidden"} // Animate when the video is ready
            exit="exit"
            variants={variantsYT}
            transition={{
              duration: 0.666,
              ease: [0.43, 0.13, 0.23, 0.96],
            }}
          >
            {isPlaying && (
              <>
                <IconClose className={styles.close} onClick={(e) => startUpVideo(e)} />
                <YouTube
                  videoId={video_id}                  // defaults -> ''
                  className={`${styles.youtube} ${isPlaying ? styles.youtubeShown : ''}`}
                  opts={opts}                        // defaults -> {}
                  onReady={handleVideoReady}
                  // onPlay={func}                     // defaults -> noop
                  // onPause={startUpVideo()}                    // defaults -> noop
                  onEnd={(e) => startUpVideo()}                      // defaults -> noop
                  // onError={func}                    // defaults -> noop
                  // onStateChange={func}              // defaults -> noop
                  // onPlaybackRateChange={func}       // defaults -> noop
                  // onPlaybackQualityChange={func}    // defaults -> noop
                />
              </>
            )}
          </motion.div>
        </AnimatePresence>
      )}
      <div className={styles.image}>
        {image && (
          <img
            src={video_id ? `https://i.ytimg.com/vi/${video_id}/hqdefault.jpg` : './classes/' + image}
            alt={title}
            loading='lazy'
          />
        )}
        {!image && (
          <img
            src={'fallback.png'}
            alt={title}
            loading='lazy'
          />
        )}
        <div className={styles.imageMeta}>
          {league_date && (
            <span className={styles.date}>
              {league_date}
            </span>
          )}
          {challenges && (
            <span className={styles.challenges}>
              {challenges} <span className={styles.faded}>challenges</span>
            </span>
          )}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.meta}>
          <span className={styles.duration}>
            <span>{league_number ? league_number + ' ' : ''}{league_name}</span>
          </span>
          {dps && (
            <span className={styles.duration}>
              {formatNumberWithSuffix(dps)} <span className={styles.faded}>dps</span>
            </span>
          )}
        </div>
        <h3 className={styles.title}>{title}</h3>
        <div className={styles.description}>
          {level && image && (
            <p><strong>{level}</strong> {league_class ? league_class : ''} {image.substring(0, image.length - 4)}</p>
          )}
        </div>
        <div className={styles.tags}>
          <a
            className={styles.tag}
            href={link}
            rel='noopener noreferrer'
            target='_blank'
          >
            <IconPoE />
          </a>
          <a
            className={styles.tag}
            href={poeninja}
            rel='noopener noreferrer'
            target='_blank'
          >
            <IconNinja />
          </a>
          <a
            className={styles.tag}
            href={poeofficial}
            rel='noopener noreferrer'
            target='_blank'
          >
            <IconPOE />
          </a>
          <a
            className={styles.tag}
            href={poestack}
            rel='noopener noreferrer'
            target='_blank'
          >
            <IconPoEStack />
          </a>
          {video_id && (
            <div
              className={styles.tag}
              onClick={(e) => startUpVideo(e)}
              href={`https://youtu.be/${video_id}`}
            >
              <IconYouTube />
            </div>
          )}
        </div>
      </div>
    </motion.article>
  );
}