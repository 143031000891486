import React, { useState, useEffect, useCallback, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import Feed from './components/feed/feed';
import Player from './components/player/player';
import Audio from './components/player/audio';
import Search from './components/search/search';
import Links from './components/links/links';
import Shortcuts from './components/shortcuts/shortcuts';
import PrivacyPolicy from './components/privacy/privacy';
import Dopamine from './components/dopamine/dopamine';

// import { cleanUpChannelName } from './utils/utility';
import { getData } from './utils/data';

import styles from './styles/startpage.module.scss';
import packageJson from '../package.json';

// Icons
// import { ReactComponent as IconPodcasts } from './icons/icon__podcasts.svg';
// import { ReactComponent as IconYouTube } from './icons/icon__youtube.svg';
// import { ReactComponent as IconMusic } from './icons/icon__music.svg';
import { ReactComponent as IconBranch } from './icons/icon__branch.svg';
// import { ReactComponent as IconClose } from './icons/icon__close.svg';
// import { ReactComponent as IconSearch } from './icons/icon__search.svg';
// import { ReactComponent as IconArticle } from './icons/icon__article.svg';
// import { ReactComponent as IconTag } from './icons/icon__tag.svg';
import { ReactComponent as IconInfo } from './icons/icon__info.svg';

const tierZero = [
  `Heavy Belt`,
  `2x Heavy Belt`,
  `Leather Belt`,
  `Valdo's Puzzle Box`,
  `Mirror of Kalandra`,
  `House of Mirrors`,
  `2x House of Mirrors`,
  `Divine Orb`,
  `Divine Orb`,
  `Divine Orb`,
  `Divine Orb`,
  `Divine Orb`,
  `Divine Orb`,
  `Winged Divination Scarab`,
  `2x Divine Orb`,
  `3x Divine Orb`,
  `5x Divine Orb`,
  `10x Divine Orb`,
  `Tempering Orb`,
  `Vaal Regalia`,
  `Elegant Round Shield`,
  `Exquisite Leather`,
  `Cobalt Jewel`,
  `Gold Ring`,
  `Eternal Orb`,
  `Fracturing Orb`,
  `Fracturing Orb`,
  `Albino Rhoa Feather`,
  `Orb of Dominance`,
  `FAILED`,
  `FAILED`,
];

function App() {
  const [stateHistory, setStateHistory] = useState([]);
  const [fullscreen, toggleFullscreen] = useState(false);
  const [music, setMusic] = useState(null);
  const [musicPlaylist, setMusicPlaylist] = useState([]);
  const [tagNavigation, setTagNavigation] = useState(false);
  const [musicTagNavigation, setMusicTagNavigation] = useState(false);
  const [pubNavigation, setPubNavigation] = useState(false);
  const [vidNavigation, setVidNavigation] = useState(false);
  const [musicNavigation, setMusicNavigation] = useState(false);
  const [podNavigation, setPodNavigation] = useState(false);
  const [search, setSearch] = useState(false);
  const [dopamine, setDopamine] = useState([]);
  const [searchFeed, setSearchFeed] = useState(false);
  const [BG, setBG] = useState(Math.floor(Math.random() * 16) + 1);
  const ref = useRef(null);
  const version = packageJson.version;

  let handleFeedNav;

  const handleMusic = useCallback((d) => {
    setMusic(d);
  }, []);

  const handleMusicPlaylist = useCallback((d) => {
    setMusicPlaylist((prevPlaylist) => [...prevPlaylist, d]);
  }, []);

  const handleMusicRemovePlaylist = useCallback((d) => {
    setMusicPlaylist((prevPlaylist) => {
      let found = false;
      return prevPlaylist.filter(item => {
        if (!found && item === d) {
          found = true;
          return false;
        }
        return true;
      });
    });
  }, []);

  const handleTagNav = useCallback((d) => {
    setPubNavigation(false);
    setVidNavigation(false);
    setMusicNavigation(false);
    setPodNavigation(false);
    setTagNavigation(d);
  }, []);

  const handleMusicTagNav = useCallback((d) => {
    setPubNavigation(false);
    setVidNavigation(false);
    setMusicNavigation(false);
    setPodNavigation(false);
    setTagNavigation(false);
    setMusicTagNavigation(d);
  }, []);

  const handlePubNav = useCallback((d) => {
    setTagNavigation(false);
    setVidNavigation(false);
    setMusicNavigation(false);
    setPodNavigation(false);
    setMusicTagNavigation(false);
    setPubNavigation(d);
  }, []);

  const handleVidNav = useCallback((d) => {
    setTagNavigation(false);
    setPubNavigation(false);
    setMusicNavigation(false);
    setPodNavigation(false);
    setMusicTagNavigation(false);
    setVidNavigation(d);
  }, []);

  const handleMusicNav = useCallback((d) => {
    setTagNavigation(false);
    setPubNavigation(false);
    setVidNavigation(false);
    setPodNavigation(false);
    setMusicTagNavigation(false);
    setMusicNavigation(d);
  }, []);

  const handlePodNav = useCallback((d) => {
    setTagNavigation(false);
    setPubNavigation(false);
    setVidNavigation(false);
    setMusicNavigation(false);
    setMusicTagNavigation(false);
    setPodNavigation(d);
  }, []);

  const variants = {
    hidden: { opacity: 0, x: "545px", maxWidth: 0 },
    visible: { opacity: 1, x: "0", maxWidth: "272px" },
    delay: 0.333,
  };

  const variantsFade = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  // const variantsTags = {
  //   hidden: { opacity: 0, y: "100px" },
  //   visible: { opacity: 1, y: "0" },
  //   // scrollintoview: { opacity: 1, y: '0' },
  //   delay: 0,
  // };

  const [activeFeed, setActiveFeed] = useState({
    feed: "search/poe",
    feedType: "search",
    sendDataToParent: handleMusic,
    tagNav: handleTagNav,
    musicTagNav: handleMusicTagNav,
    pubNav: handlePubNav,
    vidNav: handleVidNav,
    musicNav: handleMusicNav,
    musicPlaylistNav: handleMusicPlaylist,
    playlistRemoveNav: handleMusicRemovePlaylist,
    podNav: handlePodNav,
    feedNav: handleFeedNav,
    // innerModalActiveNav: handleInnerModalActive,
  });

  const setFeed = useCallback(
    (e = false, feed = { feed: `search/poe`, feedType: `search` }) => {
      if (e) e.preventDefault();
      // if (activeFeed.feed === feed.feed) return;
      setActiveFeed({
        feed: feed.feed,
        feedType: feed.feedType,
        sendDataToParent: handleMusic,
        tagNav: handleTagNav,
        musicTagNav: handleMusicTagNav,
        pubNav: handlePubNav,
        vidNav: handleVidNav,
        musicNav: handleMusicNav,
        musicPlaylistNav: handleMusicPlaylist,
        playlistRemoveNav: handleMusicRemovePlaylist,
        podNav: handlePodNav,
        feedNav: handleFeedNav,
        // innerModalActiveNav: handleInnerModalActive,
      });
    },
    [
      handleMusic,
      handleMusicTagNav,
      handleTagNav,
      handlePubNav,
      handleVidNav,
      handleMusicNav,
      handlePodNav,
      handleFeedNav,
      handleMusicPlaylist,
      handleMusicRemovePlaylist,
      setActiveFeed,
    ]
  );

  handleFeedNav = useCallback(
    (d) => {
      setFeed(false, d);
    },
    [setFeed]
  );

  const rewindFeed = useCallback(() => {
    if (stateHistory && stateHistory.length > 1) {
      const [_, previousState, ...otherHistory] = stateHistory;
      if (_) {
        if (_.feed === activeFeed.feed) {
          setActiveFeed(previousState);
          setStateHistory(otherHistory);
        }
      }
      setActiveFeed(previousState);
      setStateHistory(otherHistory);
    }
  }, [stateHistory, activeFeed.feed]);

  const addRandomItem = () => {
    const randomCount = Math.floor(Math.random() * 3 + 1); // Decide how many new items to add
    const newItems = [];

    for (let i = 0; i < randomCount; i++) {
      const newItem = {
        item: tierZero[Math.floor(Math.random() * tierZero.length)],
        x: Math.floor(Math.random() * (window.innerWidth - 250)),
        y: Math.floor(Math.random() * (window.innerHeight - 250)),
      };
      newItems.push(newItem);
    }

    setDopamine(dopamine => [...dopamine, ...newItems]); // Use functional update to ensure the most current state is used
  };


  // const handleReset = useCallback(() => {
  //   rewindFeed();
  //   setTagNavigation(false);
  //   setMusicTagNavigation(false);
  //   setPubNavigation(false);
  //   setVidNavigation(false);
  //   setMusicNavigation(false);
  //   setPodNavigation(false);
  // }, [rewindFeed]);

  useEffect(() => {
    if (tagNavigation) {
      setFeed(false, {
        feed: `news/${tagNavigation}`,
        feedType: `news`,
      });
    }
  }, [tagNavigation, setFeed]);

  useEffect(() => {
    if (musicTagNavigation) {
      setFeed(false, {
        feed: `music/tag/${musicTagNavigation}`,
        feedType: `music`,
      });
    }
  }, [musicTagNavigation, setFeed]);

  useEffect(() => {
    if (pubNavigation) {
      setFeed(false, {
        feed: `news/feed/${pubNavigation}`,
        feedType: `news`,
      });
    }
  }, [pubNavigation, setFeed]);

  useEffect(() => {
    if (vidNavigation) {
      setFeed(false, {
        feed: `videos/feed/${vidNavigation}`,
        feedType: `videos`,
      });
    }
  }, [vidNavigation, setFeed]);

  useEffect(() => {
    if (podNavigation) {
      setFeed(false, {
        feed: `podcasts/${podNavigation}`,
        feedType: `podcasts`,
      });
    }
  }, [podNavigation, setFeed]);

  useEffect(() => {
    if (musicNavigation) {
      setFeed(false, {
        feed: `music/feed/${musicNavigation}`,
        feedType: `music`,
      });
    }
  }, [musicNavigation, setFeed]);

  useEffect(() => {
    if (searchFeed) {
      setFeed(false, {
        feed: `search/${searchFeed}`,
        feedType: `search`,
      });
    }
  }, [searchFeed, setFeed]);

  useEffect(() => {
    setTimeout(() => {
      if (ref.current) ref.current.scrollTop = 0;
    }, 100);
  }, [activeFeed]);

  useEffect(() => {
    if (activeFeed) {
      if (activeFeed.feedType !== "news") {
        setTagNavigation(false);
        setPubNavigation(false);
      }
      if (activeFeed.feedType !== "videos") {
        setVidNavigation(false);
      }
      if (activeFeed.feedType !== "music") {
        setMusicNavigation(false);
        setMusicTagNavigation(false);
      }
      if (activeFeed.feedType !== "podcasts") {
        setPodNavigation(false);
      }
      if (activeFeed.feedType !== "search") {
        setSearchFeed(false);
      }
    }
  }, [
    tagNavigation,
    musicTagNavigation,
    vidNavigation,
    musicNavigation,
    podNavigation,
    pubNavigation,
    activeFeed,
  ]);

  useEffect(() => {
    setStateHistory((prevHistory) => [activeFeed, ...prevHistory].slice(0, 25));
  }, [activeFeed]);

  useEffect(() => {
    if (!music && musicPlaylist.length > 0) {
      const [first, ...rest] = musicPlaylist;
      setMusic(first);
      setMusicPlaylist(rest);
    }
  }, [music, musicPlaylist]);

  useEffect(() => {
    const handleKey = (e) => {
      if (e.shiftKey) return;
      if (!ref.current) return;

      switch (e.keyCode) {
        case 220: // \
          e.preventDefault();
          getData("music/random").then((data) => {
            if (data) handleMusic(data);
          });
          break;
        case 38: // up arrow
          e.preventDefault();
          if (ref.current.scrollTop === 0) return;
          ref.current.scrollTop -= ref.current.offsetHeight / 2;
          break;
        case 40: // down arrow
          e.preventDefault();
          ref.current.scrollTop += ref.current.offsetHeight / 2;
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKey);
    return () => {
      window.removeEventListener("keydown", handleKey);
    };
  }, [ref, handleMusic]);

  // const handleScroll = (e) => {
  //   e.preventDefault();

  //   if (innerModalActive || search) return;

  //   if (ref.current) {
  //     const halfHeight = ref.current.offsetHeight / 2;
  //     const scrollDistance = e.deltaY > 0 ? halfHeight : -halfHeight;

  //     ref.current.scrollBy({
  //       top: scrollDistance,
  //       behavior: "smooth",
  //     });
  //   }
  // };

  // function random() {
  //   return (Math.floor(Math.random() * 260) + 1).toString().padStart(4, "0");
  // }

  useEffect(() => {
    const handleKey = (e) => {
      if (e.keyCode === 191) {
        e.preventDefault();
        setSearch(!search);
      }
      if (e.keyCode === 27) {
        e.preventDefault();
        setSearch(false);
      }
    };
    window.addEventListener("keydown", handleKey);
    return () => {
      window.removeEventListener("keydown", handleKey);
    };
  }, [search]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      switch (e.keyCode) {
        case 221: // Key code for ']'
          e.preventDefault();
          toggleFullscreen(true);
          break;
        case 219: // Key code for '['
          e.preventDefault();
          toggleFullscreen(false);
          break;
        case 73: // Key code for i
          if (!search) {
            e.preventDefault();
            setFeed(e, { feed: "shortcuts", feedType: "shortcuts" });
          }
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [fullscreen, search, setFeed]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (!search) {
        switch (e.keyCode) {
          case 8:
            e.preventDefault();
            rewindFeed();
            break;
          default:
            break;
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [activeFeed, setFeed, rewindFeed, search, setSearchFeed]);

  return (
    <main className={styles.main}>
      <AnimatePresence>
        {/* Search */}
        {search && (
          <Search
            data={search}
            callback={setSearch}
            searchFeed={setSearchFeed}
          />
        )}

        {dopamine && (
          <Dopamine
            onToggle={setDopamine}
            dopamine={dopamine}
          />
        )}

        {/* Background */}
        <motion.div
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={variantsFade}
          transition={{
            duration: 2,
            ease: "easeInOut",
          }}
          key={BG}
        >
          <div
            className={styles.background}
            style={{
              backgroundImage: `url(https://startyparty.nyc3.cdn.digitaloceanspaces.com/backgrounds/pathofexile/${BG}.jpg)`,
            }}
          />
          <div className={styles.version}>
            <div
              className={styles.versionInner}
              onClick={(e) => {
                setFeed(e, { feed: "privacy", feedType: "privacy" });
                setPodNavigation(false);
              }}
            >
              <IconInfo />
              <span>Privacy</span>
            </div>
            <div className={styles.versionInner}>
              <IconBranch />
              <span>{version}</span>
            </div>
          </div>
        </motion.div>
      </AnimatePresence>

      {/* Inner */}
      <div
        className={`${
          fullscreen
            ? `${styles.fullscreen} ${styles.mainInner}`
            : styles.mainInner
        }`}
      >
        {/* Container */}
        <div
          className={`${styles.container} ${
            fullscreen ? styles.fullscreen : ""
          }`}
        >

          {/* Tag Navigations */}
          {/* <AnimatePresence key={`tagnavs`}>
            {tagNavigation && (
              <motion.div
                key={tagNavigation}
                initial="hidden"
                animate={tagNavigation ? "visible" : "hidden"}
                exit="hidden"
                variants={variantsTags}
                transition={{
                  duration: 0.666,
                  ease: [0.43, 0.13, 0.23, 0.96],
                }}
                className={styles.tagNav}
                onClick={() => handleReset()}
              >
                <IconTag className={styles.tagNavShown} />
                <IconClose className={styles.tagNavClose} />
                <span>{tagNavigation}</span>
              </motion.div>
            )}
            {musicTagNavigation && (
              <motion.div
                key={musicTagNavigation}
                initial="hidden"
                animate={musicTagNavigation ? "visible" : "hidden"}
                exit="hidden"
                variants={variantsTags}
                transition={{
                  duration: 0.666,
                  ease: [0.43, 0.13, 0.23, 0.96],
                }}
                className={styles.tagNav}
                onClick={() => handleReset()}
              >
                <IconTag className={styles.tagNavShown} />
                <IconClose className={styles.tagNavClose} />
                <span>{musicTagNavigation}</span>
              </motion.div>
            )}
            {vidNavigation && (
              <motion.div
                key={vidNavigation}
                initial="hidden"
                animate={vidNavigation ? "visible" : "hidden"}
                exit="hidden"
                variants={variantsTags}
                transition={{
                  duration: 0.666,
                  ease: [0.43, 0.13, 0.23, 0.96],
                }}
                className={styles.tagNav}
                onClick={() => handleReset(null)}
              >
                <IconYouTube className={styles.tagNavShown} />
                <IconClose className={styles.tagNavClose} />
                <span>{vidNavigation}</span>
              </motion.div>
            )}
            {pubNavigation && (
              <motion.div
                key={pubNavigation}
                initial="hidden"
                animate={pubNavigation ? "visible" : "hidden"}
                exit="hidden"
                variants={variantsTags}
                transition={{
                  duration: 0.666,
                  ease: [0.43, 0.13, 0.23, 0.96],
                }}
                className={styles.tagNav}
                onClick={() => handleReset(null)}
              >
                <IconArticle className={styles.tagNavShown} />
                <IconClose className={styles.tagNavClose} />
                <span>{pubNavigation}</span>
              </motion.div>
            )}
            {musicNavigation && (
              <motion.div
                key={musicNavigation}
                initial="hidden"
                animate={musicNavigation ? "visible" : "hidden"}
                exit="hidden"
                variants={variantsTags}
                transition={{
                  duration: 0.666,
                  ease: [0.43, 0.13, 0.23, 0.96],
                }}
                className={styles.tagNav}
                onClick={() => handleReset(null)}
              >
                <IconMusic className={styles.tagNavShown} />
                <IconClose className={styles.tagNavClose} />
                <span>
                  {musicNavigation
                    ? cleanUpChannelName(musicNavigation)
                    : ""}
                </span>
              </motion.div>
            )}
            {podNavigation && (
              <motion.div
                key={podNavigation}
                initial="hidden"
                animate={podNavigation ? "visible" : "hidden"}
                exit="hidden"
                variants={variantsTags}
                transition={{
                  duration: 0.666,
                  ease: [0.43, 0.13, 0.23, 0.96],
                }}
                className={styles.tagNav}
                onClick={() => handleReset(null)}
              >
                <IconPodcasts className={styles.tagNavShown} />
                <IconClose className={styles.tagNavClose} />
                <span>{podNavigation}</span>
              </motion.div>
            )}
            {searchFeed && (
              <motion.div
                key={searchFeed}
                initial="hidden"
                animate={searchFeed ? "visible" : "hidden"}
                exit="hidden"
                variants={variantsTags}
                transition={{
                  duration: 0.666,
                  ease: [0.43, 0.13, 0.23, 0.96],
                }}
                className={styles.tagNav}
                onClick={() => handleReset(null)}
              >
                <IconSearch className={styles.tagNavShown} />
                <IconClose className={styles.tagNavClose} />
                <span>
                  {searchFeed !== "poe" ? searchFeed : "path of exile"}
                </span>
              </motion.div>
            )}
          </AnimatePresence> */}

          <div
            className={`${styles.inside} ${search ? styles.insideLocked : ""}`}
            ref={ref}
          >
            <div className={styles.inner}>

              <AnimatePresence key={`mainfeed`} mode="wait">
                <div
                  className={`${search ? styles.searchShown : ""} ${
                    styles.feed
                  }`}
                >
                  {/* Links Navigations */}
                  {activeFeed && activeFeed.feedType === "links" && <Links />}

                  {/* Privacy Policy */}
                  {activeFeed && activeFeed.feedType === "privacy" && <PrivacyPolicy />}

                  {/* Shortcuts Navigations */}
                  {activeFeed && activeFeed.feedType === "shortcuts" && (
                    <Shortcuts />
                  )}

                  {/* Main Feed */}
                  {activeFeed &&
                    activeFeed.feedType !== "links" &&
                    activeFeed.feedType !== "privacy" &&
                    activeFeed.feedType !== "shortcuts" && (
                      <Feed
                        key={activeFeed.feed}
                        feed={activeFeed}
                        sendDataToParent={handleMusic}
                        tagNav={handleTagNav}
                        musicTagNav={handleMusicTagNav}
                        pubNav={handlePubNav}
                        vidNav={handleVidNav}
                        musicNav={handleMusicNav}
                        musicPlaylistNav={handleMusicPlaylist}
                        feedNav={handleFeedNav}
                        fullscreen={fullscreen}
                        musicPlaylist={musicPlaylist}
                      />
                    )}
                </div>
              </AnimatePresence>
            </div>
          </div>
        </div>

        {/* Header */}
        <div className={styles.header}>
          <AnimatePresence key={`header`}>
            <nav className={styles.nav}>
              <div
                href={1}
                className={styles.icons}
                onClick={(e) => {
                  setFeed(e, { feed: "search/poe", feedType: "search" });
                  setTagNavigation(false);
                  setPubNavigation(false);
                }}
              >
                <img
                  src={`/currency/Mirror_of_Kalandra.webp`}
                  alt={'music'}
                  loading="lazy"
                  width="42px"
                  height="auto"
                />
                <span className={styles.tooltip}>all media</span>
              </div>
              <div
                href={1}
                className={styles.icons}
                onClick={(e) => {
                  setFeed(e, { feed: "news", feedType: "news" });
                  setTagNavigation(false);
                  setPubNavigation(false);
                }}
              >
                <img
                  src={`/currency/Stacked_Deck.webp`}
                  alt={'music'}
                  loading="lazy"
                  width="42px"
                  height="auto"
                />
                <span className={styles.tooltip}>news</span>
              </div>
              <div
                href={1}
                className={styles.icons}
                onClick={(e) => {
                  setFeed(e, { feed: "music", feedType: "music" });
                  setMusicNavigation(false);
                }}
              >
                <img
                  src={`/currency/Exalted_Orb.webp`}
                  alt={'music'}
                  loading="lazy"
                  width="42px"
                  height="auto"
                />
                <span className={styles.tooltip}>music</span>
              </div>

              <div
                href={1}
                className={styles.icons}
                onClick={(e) => {
                  setFeed(e, { feed: "podcasts", feedType: "podcasts" });
                  setPodNavigation(false);
                }}
              >
                <img
                  src={`/currency/Vaal_Orb.webp`}
                  alt={'music'}
                  loading="lazy"
                  width="42px"
                  height="auto"
                />
                <span className={styles.tooltip}>podcasts</span>
              </div>

              <div
                href={1}
                className={styles.icons}
                onClick={(e) => {
                  setFeed(e, { feed: "videos", feedType: "videos" });
                  setVidNavigation(false);
                }}
              >
                <img
                  src={`/currency/Book_of_Skill.webp`}
                  alt={'music'}
                  loading="lazy"
                  width="42px"
                  height="auto"
                />
                <span className={styles.tooltip}>videos</span>
              </div>

              <div
                href={1}
                className={styles.icons}
                onClick={(e) =>
                  setFeed(e, { feed: "twitch", feedType: "twitch" })
                }
              >
                <img
                  src={`/currency/Maven's_Orb.webp`}
                  alt={'music'}
                  loading="lazy"
                  width="42px"
                  height="auto"
                />
                <span className={styles.tooltip}>twitch</span>
              </div>

              <div
                href={1}
                className={styles.icons}
                onClick={(e) =>
                  setFeed(e, { feed: "links", feedType: "links" })
                }
              >
                <img
                  src={`/currency/Orb_of_Fusing.webp`}
                  alt={'music'}
                  loading="lazy"
                  width="42px"
                  height="auto"
                />
                <span className={styles.tooltip}>links</span>
              </div>
              <span className={`${styles.divider} ${styles.active}`} />

              <div
                href={1}
                className={styles.icons}
                onClick={(e) => {
                  e.preventDefault();
                  setFeed(e, { feed: "shortcuts", feedType: "shortcuts" });
                }}
              >
                <img
                  src={`/currency/Rogue's_Marker.webp`}
                  alt={'music'}
                  loading="lazy"
                  width="42px"
                  height="auto"
                />
                <span className={styles.tooltip}>shortcuts</span>
              </div>
              <div
                href={1}
                className={`${styles.icons} ${styles.iconsMobileHide}`}
                onClick={(e) => {
                  e.preventDefault();
                  toggleFullscreen(!fullscreen);
                }}
              >
                <img
                  src={`/currency/Regal_Orb.webp`}
                  alt={'music'}
                  loading="lazy"
                  width="42px"
                  height="auto"
                />
                <span className={styles.tooltip}>resize</span>
              </div>
              <div
                href={1}
                className={styles.icons}
                onClick={(e) => {
                  e.preventDefault();
                  setBG(BG < 16 ? BG + 1 : 0);
                }}
              >
                <img
                  src={`/currency/Chromatic_Orb.webp`}
                  alt={'music'}
                  loading="lazy"
                  width="42px"
                  height="auto"
                />
                <span className={styles.tooltip}>Background</span>
              </div>
              <div
                href={1}
                className={styles.icons}
                onClick={(e) => {
                  e.preventDefault();
                  setSearch(!search);
                }}
              >
                <img
                  src={`/currency/Scroll_of_Wisdom.webp`}
                  alt={'music'}
                  loading="lazy"
                  width="42px"
                  height="auto"
                />
                <span className={styles.tooltip}>search</span>
              </div>
              <div
                href={1}
                className={`${styles.icons} ${styles.iconsMobileHide}`}
              >
                <a href="steam://run/238960/">
                  <img
                    src={`/poe_logo.png`}
                    alt='Launch Steam Game'
                    title='Launch Steam Game'
                    loading="lazy"
                    width="42px"
                    height="auto"
                  />
                  <span className={styles.tooltip}>Login</span>
                </a>
              </div>
              <div
                href={1}
                className={`${styles.icons} ${styles.iconsMobileHide}`}
                onClick={(e) => {
                  e.preventDefault();
                  addRandomItem();
                }}
              >
                <img
                  src={`/currency/Divine_Orb.webp`}
                  alt='Launch Steam Game'
                  title='Launch Steam Game'
                  loading="lazy"
                  width="42px"
                  height="auto"
                />
                <span className={styles.tooltip}>Dopamine</span>
              </div>
            </nav>

            {music && (
              <motion.div
                key={music.title}
                initial="hidden"
                animate={music ? "visible" : "hidden"}
                exit="hidden"
                variants={variants}
                transition={{
                  duration: 0.666,
                  ease: [0.43, 0.13, 0.23, 0.96],
                }}
                className={styles.playerMotion}
              >
                {music.type === 'music' && (
                  <Player
                    data={music}
                    handleMusic={handleMusic}
                    playlist={musicPlaylist}
                  />
                )}
                {music.type === "podcast" && (
                  <Audio data={music} handleMusic={handleMusic} />
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </main>
  );
}

export default App;
