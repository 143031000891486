import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

import RelativeTimestamp from '../../utils/timeago';
import { formatNumberWithCommas } from '../../utils/utility';
import { variants } from '../../utils/variants';

import styles from './twitch.module.scss';

import { ReactComponent as IconEye } from '../../icons/icon__eye.svg';

export default function Twitch(props) {
  const {
    title,
    viewer_count,
    link,
    user_name,
    user_login,
    // pub,
    // thumbnail_url,
    started_at,
    avatar,
    game_name,
    status
  } = props.data;
  const [newStatus, setNewStatus] = useState('');

  useEffect(() => {
    const replacedStatus = status === 'live' ? styles.online : styles.offline;
    setNewStatus(replacedStatus);
  }, [status]);

  const start = new Date(started_at);
  const unixTimestamp = start.getTime() / 1000;

  const previewThumb = `https://static-cdn.jtvnw.net/previews-ttv/live_user_${user_login}-360x180.jpg?t=${Date.now()}`

  if (!props.data) return null;

  if (props.data.status === 'hidden') {
    return (
      <motion.article
        initial="hidden"
        whileInView="visible"
        variants={variants}
        className={`${styles.twitch} ${styles.twitchLarge} ${styles.twitchHidden}`}
      >
      </motion.article>
    );
  }

  return (
    <motion.article
      initial="hidden"
      whileInView="visible"
      variants={variants}
      className={`${styles.twitch} ${
        status && status === "live" ? styles.twitchLarge : ""
      } ${props.fullscreen ? styles.twitchLargeFullscreen : ""}`}
    >
      <div className={styles.glow} />
      <a
        href={`https://twitch.tv/${user_login ? user_login : user_name}`}
        target="_blank"
        rel="noopener noreferrer"
        className={newStatus}
      >
        <div className={styles.image}>
          {previewThumb && (
            <img
              src={previewThumb}
              alt={link.substring(0, 12)}
              loading="lazy"
            />
          )}
        </div>
        <div className={styles.content}>
          <div className={styles.meta}>
            <span className={styles.channel}>{game_name}</span>
            <span className={styles.duration}>
              {status && status === "live" && viewer_count > 0 && (
                <span>
                  <IconEye />
                  {viewer_count && formatNumberWithCommas(viewer_count)}
                </span>
              )}
              {unixTimestamp > 0 && (
                <RelativeTimestamp timestamp={unixTimestamp} />
              )}
              {!unixTimestamp && status === "offline" && <span>offline</span>}
            </span>
          </div>
          {status && status === "live" && (
            <>
              <h3 className={styles.title}>
                <img src={avatar} alt={user_name} loading="lazy" />
                <span>{user_name}</span>
              </h3>
              <div className={styles.description}>
                <p>{title}</p>
              </div>
            </>
          )}
        </div>
      </a>
    </motion.article>
  );
}