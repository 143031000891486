import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import { getData } from '../../utils/data';
import { formatNumberWithCommas } from '../../utils/utility';

import Article from '../article/article';
import Video from '../video/video';
import Twitch from '../twitch/twitch';
import Music from '../music/music';
import Podcast from '../podcast/podcast';
// import Game from '../game/game';
// import NFT from '../nft/nft';
// import Github from '../github/github';
// import Trakt from '../trakt/trakt';
// import Lemmy from '../lemmy/lemmy';
import PathOfExile from '../poe/poe';
// import LastFM from '../lastfm/lastfm';
// import LastFMStats from '../lastfm/stats';
// import MusicStats from '../music/stats';
// import MusicTags from '../music/tags';
// import MusicChannels from '../music/channels';
import Playlist from '../music/playlist';
import SearchArticle from '../search/article';
import Streamers from '../twitch/streamers';
// import BerserkArticle from '../berserk/article';
// import Census from '../census/census';

import styles from './feed.module.scss';

export default function Feed({ feed, fullscreen, musicPlaylist }) {
  // const [privateVideoCount, setPrivateVideoCount] = useState(0);
  // const [twitchLiveCount, setTwitchLiveCount] = useState(null);
  const [data, setData] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [loaderImage, setLoaderImage] = useState(null);

  const sendDataToParent = (p) => feed.sendDataToParent(p);
  const handletagNav = (t) => feed.tagNav(t);
  const handleMusicTagNav = (t) => feed.musicTagNav(t);
  const handlePubNav = (p) => feed.pubNav(p);
  const handleVidNav = (v) => feed.vidNav(v);
  const handleMusicNav = (s) => feed.musicNav(s);
  const handleMusicPlaylist = (s) => feed.musicPlaylistNav(s);
  const handlePodNav = (p) => feed.podNav(p);
  const handleSetInnerModal = (f) => feed.innerModalActiveNav(f);
  const handleplaylistRemoveNav = (f) => feed.playlistRemoveNav(f);

  const [loaderImages] = useState([
    `Demigod's_Presence.webp`,
    `Eye_of_Chayula.webp`,
    `Carnage_Heart.webp`,
    `Simplex_Amulet.webp`,
    `Yoke_of_Suffering.webp`,
    `The_Primordial_Chain.webp`,
    `Star_of_Wraeclast.webp`,
    `Hinekora's_Sight.webp`,
    `Atziri's_Foible_pvp_season_2.webp`,
    `Badge_of_the_Brotherhood.webp`,
    `Aul's_Uprising.webp`,
    `Viridian_Watchstone.webp`,
    `Platinum_Lira_Arthain_Watchstone.webp`,
    `Titanium_Lex_Proxima_Watchstone.webp`,
    `Ivory_Watchstone.webp`,
    `Golden_Watchstone.webp`,
    `Crimson_Watchstone.webp`,
    `Atop_the_Atlas.webp`,
    `Blueprint_Prohibited_Library.webp`,
    `Thief's_Trinket.webp`,
    `Vigilante_Contract.webp`,
    `Contract_Records_Office.webp`,
    `Contract_Breaking_the_Unbreakable.webp`,
    `Second_Wind_Support.webp`,
    `Pulverise_Support.webp`,
    `Swiftbrand_Support.webp`,
    `Trinity_Support.webp`,
    `Minion_Damage_Support.webp`,
    `Multiple_Totems_Support.webp`,
    `Melee_Physical_Damage_Support.webp`,
    `Iron_Will_Support.webp`,
    `Greater_Volley_Support.webp`,
    `Faster_Casting_Support.webp`,
    `Empower_Support.webp`,
    `Damage_on_Full_Life_Support.webp`,
    `Deadly_Ailments_Support.webp`,
    `Cluster_Traps_Support.webp`,
    `Brutality_Support.webp`,
    `Cast_on_Melee_Kill_Support.webp`,
    `Cast_when_Stunned_Support.webp`,
    `Awakened_Hextouch_Support.webp`,
    `Awakened_Greater_Multiple_Projectiles_Support.webp`,
    `Awakened_Cast_On_Critical_Strike_Support.webp`,
    `Awakened_Brutality_Support.webp`,
    `Awakened_Blasphemy_Support.webp`,
    `Awakened_Added_Fire_Damage_Support.webp`,
    `Split_Personality.webp`,
    `Voices.webp`,
    `Watcher's_Eye.webp`,
    `Thread_of_Hope.webp`,
    `To_Dust.webp`,
    `The_Blue_Dream.webp`,
    `The_Blue_Nightmare.webp`,
    `Prismatic_Jewel.webp`,
    `Lethal_Pride.webp`,
    `Inspired_Learning.webp`,
    `Kitava's_Teachings.webp`,
    `Grand_Spectrum_(Cobalt_Jewel).webp`,
    `Glorious_Vanity.webp`,
    `Crimson_Jewel.webp`,
    `Dead_Reckoning.webp`,
    `Brutal_Restraint.webp`,
    `Calamitous_Visions.webp`,
    `Wild_Thornfruit.webp`,
    `Vivid_Abberarach_Bulb.webp`,
    `Primal_Blisterfruit.webp`,
    `Sewer_Keys.webp`,
    `Vault_Key.webp`,
    `Tower_Key.webp`,
    `The_Apex.webp`,
    `Firefly_(7_of_7).webp`,
    `Firefly_(1_of_7).webp`,
    `Book_of_Skill.webp`,
    `Book_of_Skill_2.webp`,
    `Book_of_Reform.webp`,
    `Divination_card.webp`,
    `Shuddering_Fossil.webp`,
    `Portal_Scroll.webp`,
    `Orb_of_Binding.webp`,
    `Prime_Chaotic_Resonator.webp`,
    `Primitive_Alchemical_Resonator.webp`,
    `Primitive_Chaotic_Resonator.webp`,
    `Potent_Chaotic_Resonator.webp`,
    `Deafening_Essence_of_Hatred.webp`,
    `Shrieking_Essence_of_Sorrow.webp`,
    `Shrieking_Essence_of_Dread.webp`,
    `Screaming_Essence_of_Hatred.webp`,
    `Essence_of_Delirium.webp`,
    `Deafening_Essence_of_Scorn.webp`,
    `Deafening_Essence_of_Dread.webp`,
    `Warlord's_Exalted_Orb.webp`,
    `Blessing_of_Tul.webp`,
    `Crusader's_Exalted_Orb.webp`,
    `Elder's_Orb.webp`,
    `Engineer's_Orb.webp`,
    `Facetor's_Lens.webp`,
    `Golden_Oil.webp`,
    `Hunter's_Exalted_Orb-1.webp`,
    `Perandus_Coin.webp`,
    `Prophecy.webp`,
    `Ritual_Vessel.webp`,
    `Shaper's_Orb.webp`,
    `Skittering_Delirium_Orb.webp`,
    `Splinter_of_Esh.webp`,
    `Splinter_of_Chayula.webp`,
    `Tainted_Jeweller's_Orb.webp`,
    `Tainted_Chromatic_Orb.webp`,
    `Tainted_Blessing.webp`,
    `Thaumaturge's_Delirium_Orb.webp`,
    `Timeless_Delirium_Orb.webp`,
    `Vial_of_Awakening.webp`,
    `Vial_of_Dominance.webp`,
    `Veiled_Chaos_Orb.webp`,
    `Thaumaturgical_Net.webp`,
    `Tainted_Orb_of_Fusing.webp`,
    `Silver_Coin.webp`,
    `Secondary_Regrading_Lens.webp`,
    `Sanctified_Fossil.webp`,
    `Sacred_Orb.webp`,
    `Mirror_Shard.webp`,
    `Necromancy_Net.webp`,
    `Noxious_Catalyst.webp`,
    `Opalescent_Oil.webp`,
    `Journeyman_Cartographer's_Seal.webp`,
    `Hunter's_Exalted_Orb.webp`,
    `Intrinsic_Catalyst.webp`,
    `Infused_Engineer's_Orb.webp`,
    `Harbinger's_Orb.webp`,
    `Fragmentation_Scroll.webp`,
    `Hollow_Fossil.webp`,
    `Enkindling_Orb.webp`,
    `Eternal_Orb.webp`,
    `Bound_Fossil.webp`,
    `Blacksmith's_Whetstone.webp`,
    `Awakener's_Orb 2.webp`,
    `Gemcutter's_Prism.webp`,
    `Rogue's_Marker.webp`,
    `Orb_of_Fusing.webp`,
    `Orb_of_Alteration.webp`,
    `Orb_of_Annulment.webp`,
    `Jeweller's_Orb.webp`,
    `Bestiary_Orb.webp`,
    `Awakener's_Orb.webp`,
    `Awakened_Sextant.webp`,
    `Cartographer's_Chisel.webp`,
    `Scroll_of_Wisdom.webp`,
    `Stacked_Deck.webp`,
    `Vaal_Orb.webp`,
    `Tempering_Orb.webp`,
    `Redeemer's_Exalted_Orb.webp`,
    `Maven's_Orb.webp`,
    `Mirror_of_Kalandra.webp`,
    `Orb_of_Unmaking.webp`,
    `Orb_of_Scouring.webp`,
    `Orb_of_Regret.webp`,
    `Regal_Orb.webp`,
    `Orb_of_Alchemy.webp`,
    `Glassblower's_Bauble.webp`,
    `Exalted_Orb.webp`,
    `Encrusted_Fossil.webp`,
    `Elevated_Sextant.webp`,
    `Divine_Orb.webp`,
    `Crescent_Splinter.webp`,
    `Chromatic_Orb.webp`,
    `Chaos_Orb.webp`,
    `Amber_Oil.webp`,
    `Apprentice_Cartographer's_Sextant.webp`,
    `Ancient_Orb.webp`,
    `Albino_Rhoa_Feather.webp`,
  ]);

  useEffect(() => {
    preloadImages(loaderImages);
  }, [loaderImages]);

  useEffect(() => {
    setPage(0);
    setLoaderImage(loaderImages[Math.floor(Math.random() * loaderImages.length)]);
  }, [feed, loaderImages]);

  useEffect(() => {
    if (!feed) return;

    setLoading(true);

    const fetchData = async () => {
      try {
        if (feed.feedType !== 'links' ||
            feed.feedType !== 'shortcuts') {

          let d = await getData(feed.feed, page);

          if (feed.feedType === 'search') {
            setSearchResults(d);
            d = d['results'];
            d.sort((a, b) => (a.pub > b.pub) ? -1 : 1);
          }
          if (feed.feedType === 'poestreams') {
            d.sort((a, b) => (a.pub > b.pub) ? -1 : 1);
          }
          setData(d);
        }

        setLoading(false);

      } catch (error) {
        setError(error);
        setPage(0);
        setLoading(false);
      }
    };

    try {
      fetchData();
    } catch (error) {
      setData([]);
      setError(error);
      setPage(0);
      setLoading(false);
    }

  }, [feed, page]);

  // useEffect(() => {
  //   let count = 0;
  //   if (data && data.length && feed.feedType === 'videos') {
  //     data.forEach((item) => {
  //       if (item.title) {
  //         if (item.title.toLowerCase() === '[private video]') {
  //           count++;
  //         }
  //       }
  //     });
  //   }
  //   // setPrivateVideoCount(count);
  // }, [data, feed]);

  useEffect(() => {
    const handleKey = (e) => {
      if (e.shiftKey) return;

      switch (e.keyCode) {
        case 39: // Right arrow
          e.preventDefault();
          setPage(prevPage => prevPage + 1);
          break;
        case 37: // Left arrow
          e.preventDefault();
          setPage(prevPage => Math.max(0, prevPage - 1));
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKey);
    return () => {
      window.removeEventListener('keydown', handleKey);
    };
  }, [setPage]);

  const preloadImages = (imageUrls) => {
    imageUrls.forEach(url => {
      const img = new Image();
      img.src = `/currency/${url}`;
    });
  }

  const variants = {
    hidden: {
      // opacity: 0,
      y: 10,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.3,
      },
    },
    visible: {
      // opacity: 1,
      y: 0,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.3,
        ease: [0.43, 0.13, 0.23, 0.96],
        // type: 'spring',
        // bounce: 0.5,
        duration: 0.444,
      },
    },
    exit: {
      opacity: 0,
    },
    // delay: 0.333,
  };

  const noResultsVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        ease: [0.43, 0.13, 0.23, 0.96],
        duration: 0.444,
      },
      delay: 555
    },
  }

  const componentMap = {
    news: Article,
    videos: Video,
    search: SearchArticle,
    twitch: Twitch,
    // games: Game,
    // berserk: BerserkArticle,
    music: Music,
    // github: Github,
    // trakt: Trakt,
    // lemmy: Lemmy,
    poe: PathOfExile,
    podcasts: Podcast,
    // lastfm: LastFM,
    // nfts: NFT
  };

  const titleMap = {
    news: 'articles',
    videos: 'videos',
    search: 'results',
    berserk: 'chapters',
    twitch: 'streamers live',
    games: 'games',
    census: 'politicians',
    music: 'songs',
    github: 'events',
    trakt: 'upcoming episodes',
    lemmy: 'posts',
    poe: 'characters',
    podcasts: 'podcasts',
    lastfm: 'songs',
    nfts: 'NFTs'
  };

  const FeedComponent = componentMap[feed.feedType];

  if (!FeedComponent) return <div className={styles.message}><span>Invalid feed type</span></div>;

  if (loading) return (
    <motion.div
      key={loading}
      initial="hidden"
      animate={loading && !error ? "visible" : "hidden"}
      exit="hidden"
      variants={variants}
      transition={{
        duration: 0.666,
        ease: [0.43, 0.13, 0.23, 0.96],
      }}
      className={styles.message}
    >
      <span className={styles.loader}>
        <img
          src={`/currency/${loaderImage}`}
          alt="loading"
          width={72}
          height={`auto`}
        />
      </span>
    </motion.div>
  );

  if (error) return <div className={styles.message}>
    <span>Error: {error.message}</span>
  </div>;

  if (!data) return <div className={styles.message}>
    <span>No data found</span>
  </div>;

  return (
    <motion.div
      key={[page, data]}
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={variants}
    >
      {/* No Data Found */}
      {data.length === 0 && (
        <motion.div
          key={data.length === 0}
          initial="hidden"
          animate="visible"
          variants={noResultsVariants}
        >
          <div className={styles.message}>
            <span>No {titleMap[feed.feedType]} found</span>
          </div>
        </motion.div>
      )}

      {/* Feed */}
      {data && data.length > 0 && (
        <div
          className={
            fullscreen ? `${styles.feed} ${styles.fullscreen}` : styles.feed
          }
        >
          {/* Music Appended Components */}
          {feed.feed.includes("music") && (
            <>
              {musicPlaylist && musicPlaylist.length > 0 && (
                <Playlist
                  data={musicPlaylist}
                  musicNavPlaylist={handleMusicPlaylist}
                  fullscreen={fullscreen}
                  musicTagNav={handleMusicTagNav}
                  playlistRemoveNav={handleplaylistRemoveNav}
                  musicNav={handleMusicNav}
                />
              )}
            </>
          )}

          {/* Main Feed */}
          {data &&
            data.length > 0 &&
            data.map((item, index) => (
              <FeedComponent
                index={index}
                key={`feed-${index}`}
                data={item}
                sendDataToParent={sendDataToParent}
                tagNav={handletagNav}
                musicTagNav={handleMusicTagNav}
                pubNav={handlePubNav}
                vidNav={handleVidNav}
                musicNav={handleMusicNav}
                musicNavPlaylist={handleMusicPlaylist}
                podNav={handlePodNav}
                fullscreen={fullscreen}
                innerModalActive={handleSetInnerModal}
                layout
              />
            ))}

          {/* Pagination */}
          {feed && feed.feedType !== "lastfm" && feed.feedType !== "twitch" && (
            <div className={styles.pagination}>
              {/* {data.length >= 48 && ( */}
              <button
                className={`${styles.paginationLeft} ${
                  page === 0 ? styles.disabled : ""
                }`}
                onClick={() => setPage(page > 0 ? page - 1 : 0)}
                disabled={page === 0}
              >
                <h2>{page > 1 ? page - 1 : `start`}</h2>
                <strong>previous page</strong>
                <span>(left arrow)</span>
              </button>
              {/* )} */}
              <button
                className={`${styles.paginationRight} ${
                  data.length < 48 ? styles.disabled : ""
                }`}
                onClick={() => setPage(page + 1)}
                disabled={data.length < 48}
              >
                <h2>{page + 1}</h2>
                <strong>next page</strong>
                <span>(right arrow)</span>
              </button>
            </div>
          )}

          {/* Footer Stats */}
          {data.length > 0 && (
            <div className={styles.count}>
              <div className={styles.countInner}>
                <strong>
                  {data.length}
                  {searchResults.total > 0 && (
                    <span>
                      {" "}
                      / {formatNumberWithCommas(searchResults.total)}
                    </span>
                  )}
                </strong>{" "}
                {titleMap[feed.feedType]}
                {searchResults.pages > 0 && (
                  <>
                    {", "}
                    <strong>{searchResults.pages}</strong>{" "}
                    <span>pages total</span>
                  </>
                )}
              </div>
            </div>
          )}

          {/* Twitch Streamer List */}
          {feed && feed.feedType === "twitch" && <Streamers />}

        </div>
      )}
    </motion.div>
  );
}
