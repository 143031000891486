import React from 'react';
import { ReactComponent as IconTime } from '../icons/icon__time.svg';

function timeAgo(unixTimestamp) {
  if (!unixTimestamp || unixTimestamp < 0) return null;

  const now = new Date().getTime();
  const secondsPast = (now - unixTimestamp * 1000) / 1000;

  if (secondsPast < 60) {
    return 'just now';
  }

  if (secondsPast < 3600) {
    const minutes = Math.floor(secondsPast / 60);
    return `${minutes}m ago`;
  }

  if (secondsPast < 86400) {
    const hours = Math.floor(secondsPast / 3600);
    return `${hours}h ago`;
  }

  if (secondsPast < 604800) {
    const days = Math.floor(secondsPast / 86400);
    if (days === 1) {
      return 'yesterday';
    }
    return `${days}d ago`;
  }

  if (secondsPast < 31536000) {
    const weeks = Math.floor(secondsPast / 604800);
    if (weeks === 1) {
      return '1w ago';
    }
    return `${weeks}w ago`;
  }

  const years = Math.floor(secondsPast / 31536000);
  return `${years}y ago`;
}

const RelativeTimestamp = ({ timestamp }) => {
  return (
    <span>
        <IconTime />
        {timeAgo(timestamp)}
    </span>
  );
}

export default RelativeTimestamp;
