import React, { useEffect, useState, useRef } from 'react';
import YouTube from 'react-youtube';
import { motion } from "framer-motion";

import { timeStringToUnixSeconds, cleanUpChannelName, checkFallbackImage } from '../../utils/utility';

import Marquee from './marquee';

import { variants } from "../../utils/variants";

import { ReactComponent as IconPlay } from '../../icons/icon__play.svg';
import { ReactComponent as IconPause } from '../../icons/icon__pause.svg';

import styles from './player.module.scss';
// import RelativeTimestamp from '../../utils/timeago';

export default function Player(props) {

  // const [player, setPlayer] = useState(null);
  const [progressBarWidth, setProgressBarWidth] = useState(0);
  const [progressInterval, setProgressInterval] = useState(null);
  const [playerState, setPlayerState] = useState(null);
  const [currentTime, setCurrentTime] = useState(null);
  const player = useRef(null);

  const {
    channel,
    duration,
    id,
    image,
    title,
  } = props.data;

  const opts = {
    height: 0,
    width: 0,
    playerVars: {
      autoplay: 1,
      controls: 0,
      showinfo: 0,
      disablekb: 1,
      enablejsapi: 1,
      modestbranding: 1,
      playsinline: 1,
      mute: 1,
      loop: 0
    },
    autoplay: 0
  };

  // Callback function when the player is ready
  const onReady = (event) => {
    clearInterval(progressInterval);
    setProgressBarWidth(0);
    // setPlayer(event.target);
    player.current = event.target;
    playVideo();
    player.current.setVolume(100);
  };

  const playVideo = () => {
    if (player.current) player.current.playVideo();
  };

  const pauseVideo = () => {
    if (player.current) player.current.pauseVideo();
  };

  // const onError = (event) => {
  //   // endPlayback();
  //   if (player) {
  //     playVideo();
  //   }
  //   console.error(event.data);
  // };

  const endPlayback = () => {
    if (player.current) player.current.stopVideo();
    setProgressBarWidth(100);
    player.current = null;
    clearInterval(progressInterval);
    props.handleMusic(null);
  }

  const onStateChange = (event) => {
    setPlayerState(event.data);

    if (player.current && player.current.isMuted()) {
      player.current.unMute();
      playVideo();
    }

    if (event.data === 1) {
      const interval = setInterval(() => {
        if (!player.current) return;
        const currentTime = player.current.getCurrentTime();
        const totalTime = player.current.getDuration();
        const newProgressBarWidth = Math.round((currentTime / totalTime) * 100);
        setProgressBarWidth(newProgressBarWidth < 1 ? 1 : newProgressBarWidth);
        setCurrentTime(timeStringToUnixSeconds(totalTime - currentTime));
      }, 1000);

      setProgressInterval(interval);

      player.current.addEventListener('onStateChange', (state) => {
        if (state.data === 0) {
          endPlayback();
        }
      });
    }

    if (event.data === 2 || !player.current) {
      clearInterval(progressInterval);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (e.shiftKey) {
      const ytUrl = `https://www.youtube.com/watch?v=${id}`;
      navigator.clipboard.writeText(ytUrl);
    } else {
      if (player.current) {
        if (player.current.getPlayerState() === 1) {
          pauseVideo();
        } else {
          playVideo();
        }
      }
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };
    if (playerState === 1) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [playerState]);

  useEffect(() => {
    const handleSeekForward = () => {
      if (player.current)
        player.current.seekTo(
          player.current.getCurrentTime() + 15
        );
    };

    const handleSeekBackward = () => {
      if (player.current)
        player.current.seekTo(
          player.current.getCurrentTime() - 5
        );
    };

    const handleIncreaseVolume = () => {
      if (player.current)
        player.current.setVolume(
          player.current.getVolume() + 10
        );
    };

    const handleDecreaseVolume = () => {
      if (player.current)
        player.current.setVolume(
          player.current.getVolume() - 10
        );
    };

    const handleToggleMute = () => {
      if (player.current)
        player.current.isMuted()
          ? player.current.unMute()
          : player.current.mute();
    };

    const handleStopVideo = () => {
      if (player.current) {
        player.current.stopVideo();
        player.current = null;
        setProgressBarWidth(100);
        props.handleMusic(null);
      }
    };

    const handleTogglePlayPause = () => {
      if (player.current) {
        if (player.current.getPlayerState() === 1) {
          player.current.pauseVideo();
        } else {
          player.current.playVideo();
        }
      }
    };

    const handleKeyPress = (e) => {
      if (player.current) {
        if (player.current.getPlayerState() === 1) {
          if (e.shiftKey) {
            e.preventDefault();
            switch (e.keyCode) {
              case 39:
                handleSeekForward();
                break;
              case 37:
                handleSeekBackward();
                break;
              case 38:
                handleIncreaseVolume();
                break;
              case 40:
                handleDecreaseVolume();
                break;
              case 77:
                handleToggleMute();
                break;
              default:
                break;
            }
          }
        }
        if (e.shiftKey) {
          if (e.keyCode === 83) {
            handleStopVideo();
          }
          if (e.keyCode === 32) {
            handleTogglePlayPause();
          }
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [player, props]);

  if (!props.data) return null;

  return (
    <div className={styles.player} onClick={(e) => handleClick(e)}>
      {id && (
        <YouTube
          videoId={id}
          opts={opts}
          onReady={onReady}
          onStateChange={onStateChange}
          onEnd={endPlayback}
          className={styles.hidden}
          // onError={onError}
        />
      )}
      <div
        className={`${styles.playerInner} ${
          playerState === 2 ? styles.paused : ""
        }`}
      >
        {props.playlist.length > 0 && (
          <motion.div
            key={props.playlist.length}
            variants={variants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className={styles.playlist}
          >
            <span>{props.playlist.length}</span>
          </motion.div>
        )}
        <div className={styles.image}>
          <img src={checkFallbackImage(image)} alt={title} loading="lazy" />
          {playerState === 1 && <IconPause className={styles.pause} />}
          {playerState === 2 && <IconPlay className={styles.play} />}
        </div>
        <div className={styles.content}>
          <div className={`${title.length > 20 ? styles.overflow : ""}`}>
            <div
              className={`${styles.contentInner} ${
                title.length > 20 ? styles.overflowInner : ""
              }`}
            >
              <h3 className={`${styles.title}`}>
                <Marquee text={`${title + ' - ' + cleanUpChannelName(channel)} `} />
                {/* {title.length > 20 && <Marquee text={`${title + ' - ' + cleanUpChannelName(channel)}`} />} */}
                {/* {title.length <= 20 && <span>{title} - {cleanUpChannelName(channel)}</span>} */}
              </h3>
            </div>
          </div>
          {/* <div className={styles.meta}>
            <span className={styles.channel}>
              {cleanUpChannelName(channel)}
            </span>
          </div> */}
          <span className={styles.duration}>
            <div className={styles.timeline}>
              <div
                className={styles.timelineInner}
                style={{ width: `${progressBarWidth}%` }}
              ></div>
            </div>
            <span className={styles.date}>
              {currentTime ? currentTime : timeStringToUnixSeconds(duration)}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}