import React, { useEffect, useState, useRef } from 'react';
import { timeStringToUnixSeconds } from '../../utils/utility';
import Marquee from './marquee';

import { ReactComponent as IconPlay } from '../../icons/icon__play.svg';
import { ReactComponent as IconPause } from '../../icons/icon__pause.svg';

import styles from './player.module.scss';

export default function Audio(props) {

  const [audioDuration, setAudioDuration] = useState(null);
  const [audioDurationFull, setAudioDurationFull] = useState(null);
  const [progressBarWidth, setProgressBarWidth] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const audioElementRef = useRef(null);

  let {
    feed,
    duration,
    media,
    image,
    title,
  } = props.data;

  useEffect(() => {
    const audioElement = audioElementRef.current;

    const updateProgressBar = () => {
      const audioElement = audioElementRef.current;

      if (audioElement) {
        const currentTime = audioElement.currentTime;
        const newProgressBarWidth = Math.round((currentTime / audioDurationFull) * 100);
        const diff = timeStringToUnixSeconds(audioDurationFull - currentTime).split('.')[0];
        setAudioDuration(diff);
        setProgressBarWidth(newProgressBarWidth < 1 ? 1 : newProgressBarWidth);
      }
    };

    if (audioElement) {
      audioElement.onloadedmetadata = () => {
        setProgressBarWidth(1);
        setAudioDurationFull(audioElement.duration);
      };

      audioElement.playbackRate = 1.3;
      // audioElement.volume = 0.75;
      audioElement.addEventListener('timeupdate', updateProgressBar);

      return () => {
        audioElement.removeEventListener('timeupdate', updateProgressBar);
        audioElement.onloadedmetadata = null;
      };
    }
  }, [audioDurationFull, duration]);

  useEffect(() => {
    const audioElement = audioElementRef.current;
    audioElement.onended = () => {
      if (audioElement) audioElement.pause();
      setProgressBarWidth(100);
      setIsPlaying(false);
      props.handleMusic(null);
    }
  }, [props, duration]);

  const handlePlayPause = (e) => {
    e.preventDefault();
    const audioElement = audioElementRef.current;
    if (audioElement) {
      if (audioElement.paused) {
        audioElement.play();
      } else {
        audioElement.pause();
      }
      setIsPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      const audioElement = audioElementRef.current;
      if (audioElement) {
        if (e.shiftKey && e.keyCode === 39) {
          e.preventDefault();
          audioElement.currentTime += 15;
        }
        if (e.shiftKey && e.keyCode === 37) {
          e.preventDefault();
          audioElement.currentTime -= 5;
        }
        if (e.shiftKey && e.keyCode === 32) {
          e.preventDefault();
          if (audioElement.paused) {
            audioElement.play();
          } else {
            audioElement.pause();
          }
          setIsPlaying(!isPlaying);
        }
        if (e.shiftKey && e.keyCode === 38) {
          e.preventDefault();
          audioElement.volume = audioElement.volume < 0.95 ? audioElement.volume + 0.1 : 0.95;
        }
        if (e.shiftKey && e.keyCode === 40) {
          e.preventDefault();
          audioElement.volume = audioElement.volume > .1 ? audioElement.volume - 0.1 : 0;
        }
        if (e.shiftKey && e.keyCode === 77) {
          e.preventDefault();
          audioElement.muted = !audioElement.muted;
        }
        if (e.shiftKey && e.keyCode === 83) {
          e.preventDefault();
          if (audioElement) {
            audioElement.pause();
            audioElement.currentTime = 0;
          }
          setProgressBarWidth(100);
          setIsPlaying(false);
          props.handleMusic(null);
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [isPlaying, props]);

  useEffect(() => {
    const audioElement = audioElementRef.current;
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };
    if (audioElement && audioElement.paused) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  if (!props.data) return null;

  return (
    <div className={styles.player} onClick={(e) => handlePlayPause(e)}>
      {media && (
        <audio
          className={styles.hidden}
          ref={audioElementRef}
          autoPlay
        >
          <source src={media} type="audio/mpeg" />
        </audio>
      )}
      <div
        className={`${styles.playerInner} ${isPlaying ? styles.paused : ''}`}
      >
      <div className={styles.image}>
        <img
          src={image}
          alt={title}
          loading='lazy'
        />
        {isPlaying && (
          <IconPlay className={styles.play} />
        )}
        {!isPlaying && (
          <IconPause className={styles.pause} />
        )}
      </div>
      <div className={styles.content}>
        <div className={`${title.length > 20 ? styles.overflow : '' }`}>
          <div className={`${styles.contentInner} ${title.length > 20 ? styles.overflowInner : '' }`}>
            <h3 className={`${styles.title} ${ title.length > 20 ? styles.marquee : '' }`}>
              {title.length > 20 && <Marquee text={title} />}
              {title.length <= 20 && <span>{title}</span>}
            </h3>
          </div>
        </div>
        <div className={styles.meta}>
          <span className={styles.channel}>{feed}</span>
        </div>
        <span className={styles.duration}>
          <div className={styles.timeline}>
            <div
              className={styles.timelineInner}
              style={{ width: `${progressBarWidth}%` }}
            ></div>
          </div>
          {audioDuration && (
            <span className={styles.date}>
              {audioDuration}
            </span>
          )}
        </span>
      </div>
    </div>
  </div>
  );
}