import { motion } from 'framer-motion';

import styles from './privacy.module.scss';

export default function PrivacyPolicy() {

  const variantsOuter = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };

  // on hover of styles.channel, play sound /AlertSound6.mp3
  const handleHover = (e) => {
    const audio = new Audio('/AlertSound6.mp3');
    audio.volume = 0.3;
    audio.play();
  }

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      variants={variantsOuter}
      className={styles.container}
      key={`links`}
    >

      <h2 className={styles.dividerTitle}>Privacy Policy</h2>
      <div className={styles.divider}>
        <img src="/footer--alt.png" alt="" className={styles.dividerImage} />
      </div>

      <article className={styles.article}>
        <div className={styles.content}>
          <div className={styles.contentInner}>

            <p><strong>Last Updated:</strong> January 18, 2024</p>

            <h3>Introduction</h3>
            <p>Welcome, exile, to <strong className={styles.mediaChannel}>pathofexile.media</strong>. In the spirit of clarity and command, I present this privacy policy - a testament to how information is managed within these digital dominions. Heed these words well before you venture further.</p>

            <h3>Information Collection and Use</h3>
            <p>In my realm at <strong className={styles.mediaChannel}>pathofexile.media</strong>, your privacy stands as a bastion, unbreached. Behold the tenets I uphold with unwavering resolve:</p>
            <ul>
              <li>No shadowy tracking cookies or analytics tools shall find sanctuary here - that is my solemn vow to you.</li>
              <li>No alliances are forged with third-party services for the purpose of tracking or advertising.</li>
              <li>Your personal data remains untouched, unclaimed - I seek no dominion over it.</li>
            </ul>
            <p>The content that graces these pages is drawn from various APIs and RSS feeds. Let it be known, I lay no claim to this content's origins.</p>

            <h3>Data Storage and Security</h3>
            <p>As no personal data falls under my reign, there is naught to store or shield. However, let it be known that the fortifications safeguarding this site are of the highest order.</p>

            <h3>Third-Party Links</h3>
            <p>At times, portals to other realms may manifest within these walls. I decree no responsibility for the content or privacy machinations of these foreign lands. Exercise your own judgement upon crossing these thresholds.</p>

            <h3>Children's Privacy</h3>
            <p>This domain is no place for the young and untested. Should it come to pass that a child under 13 has traversed these lands and left their mark, such traces will be expunged with haste.</p>

            <h3>Changes to This Privacy Policy</h3>
            <p>As the sands shift, so too may the edicts of this policy. Updates will be inscribed here, on this very page. Vigilance is advised, though I am cognizant of the rarity with which these texts are perused.</p>

            <h3>Your Consent</h3>
            <p>By treading upon these grounds, you acknowledge and revere this privacy policy, and pay homage to GGG for their grand creation. Your compliance is appreciated.</p>

            <h3>Contact Us</h3>
            <p>Should you wish to parley - be it queries, counsel, or simply to impart your greetings -</p>
            <p>Convey your missive to <a className={styles.channel} onMouseEnter={handleHover} href="mailto:m@arbitrarily.me">My Email</a>.</p>
            <p>Ensure the title bears the mark of <strong>pathofexile.media</strong>. I await your words with bated anticipation 😏</p>

            <sub>Yes, this was AI generated in the tone of Dominus.</sub>

          </div>
        </div>
      </article>
    </motion.div>
  );
}