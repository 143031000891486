export const variants = {
  hidden: {
    opacity: 0,
    y: 12.5,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.43, 0.13, 0.23, 0.96],
      type: 'spring',
      bounce: 0.5,
      duration: 0.5
    }
  },
};