import styles from "./music.module.scss";
import { motion } from "framer-motion";

import { variants } from "../../utils/variants";

import Music from "./music";

export default function Playlist(props) {
  if (!props.data) return null;

  const handleMusicPlaylist = (s) => props.musicNavPlaylist(s);
  const handlePlaylistRemoveNav = (s) => props.playlistRemoveNav(s);

  return (
    <>
    <motion.div
      key={props.data}
      initial="hidden"
      animate="visible"
      variants={variants}
      className={`${styles.grid} ${styles.gridPlaylist}`}
    >
      <h2 className={styles.dividerTitle}>Playlist x{props.data.length}</h2>
      <img src="/footer--alt.png" alt="" className={styles.dividerImage} />
      <div className={`${styles.musicRow}`}>
        {Array.isArray(props.data)
          ? props.data.map((post, index) => (
            <Music
              key={index}
              data={post}
              musicNavPlaylist={handleMusicPlaylist}
              fullscreen={props.fullscreen}
              sendDataToParent={props.sendDataToParent}
              playlistRemoveNav={handlePlaylistRemoveNav}
              musicNav={props.musicNav}
              musicTagNav={props.musicTagNav}
              playlistCheck={true}
            />
          ))
          : Object.values(props.data).map((post, index) => (
            <Music key={index} data={post} fullscreen={props.fullscreen} />
          ))}
      </div>
    </motion.div>
    {props.data.length > 0 && (
      <>
      <h2 className={styles.dividerTitle}>Tracks</h2>
      <img src="/footer--alt.png" alt="" className={styles.dividerImage} />
      </>
    )}
    </>
  );

}
