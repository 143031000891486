import { useEffect } from 'react';
import { motion } from 'framer-motion';

import styles from './dopamine.module.scss';

function Dopamine({ dopamine, onToggle }) {

  const variants = {
    hidden: { marginTop: -150 },
    visible: { marginTop: 0 },
    exit: { marginTop: 0 },
    delay: 0.05,
  };

  useEffect(() => {
    // Play sound for each non-FAILED item added
    if (dopamine.length === 0) {
      return;
    }
    const lastItem = dopamine[dopamine.length - 1];
    if (lastItem && lastItem.item !== 'FAILED') {
      const audio = new Audio('/AlertSound6.mp3');
      audio.volume = 0.3;
      audio.play().catch(e => console.error('Error playing audio:', e));
    }
  }, [dopamine]);

  const handleClickRemoveClickedItem = () => {
    onToggle([]); // Remove the clicked item
  };

  if (!dopamine.length) {
    return null;
  }

  return dopamine.map((d, index) => (
    <motion.div
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={variants}
      transition={{ duration: 0.075, ease: [0.43, 0.13, 0.23, 0.96] }}
      key={index}
      className={d.item === 'FAILED' ? styles.itemDisconnected : styles.item}
      onClick={handleClickRemoveClickedItem}
      style={{ left: `${d.x}px`, top: `${d.y}px` }}
    >
      <img src={d.item === 'FAILED' ? "/failed.png" : "/icon__star.png"} alt="Item" width={d.item === 'FAILED' ? 606 : 26} height={d.item === 'FAILED' ? 180 : 26} />
      {d.item !== 'FAILED' && <span>{d.item}</span>}
    </motion.div>
  ));

}

export default Dopamine;