import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import YouTube from 'react-youtube';

import RelativeTimestamp from '../../utils/timeago';
import { formatNumberWithSuffix, timeStringToUnixSeconds, checkFallbackImage } from "../../utils/utility";

import { ReactComponent as IconClose } from '../../icons/icon__close.svg';
import { ReactComponent as IconEye } from '../../icons/icon__eye.svg';

import styles from './video.module.scss';

import { variants } from '../../utils/variants';

export default function Video(props) {
  const [player, setPlayer] = useState(null);
  const [playerState, setPlayerState] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoReady, setVideoReady] = useState(false);

  const {
    title,
    duration,
    id,
    channel,
    channel_id,
    pub,
    image,
    description,
    type,
    view_count,
  } = props.data;

  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 1,
      controls: 1,
      disablekb: 1,
      enablejsapi: 1,
      modestbranding: 1,
      playsinline: 1,
      origin: window.location.origin,
    },
    autoplay: 1
  };

  const variantsYT = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      ease: [0.43, 0.13, 0.23, 0.96],
      transition: {
        duration: 1,
      },
      delay: 0.666,
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (e.target.className === styles.channel) {
      props.vidNav(channel);
    } else {
      setPlayer(e.target);
      setIsPlaying(!isPlaying);
      playVideo();
    }
  };

  const onStateChange = (event) => {
    setPlayerState(event.data);
  };

  const endPlayback = () => {
    if (player) player.stopVideo();
    setTimeout(() => {
      setIsPlaying(false);
      setPlayer(null);
    }, 667);
  }

  const handleVideoReady = (e) => {
    setVideoReady(true);
    setPlayer(e.target);
    playVideo();
    props.sendDataToParent(false);
  };

  const playVideo = () => {
    if (player && player.playVideo) {
      player.playVideo();
    }
  };

  const handleStopVideo = useCallback(() => {
    if (player) {
      player.stopVideo();
      setPlayer(null);
      setIsPlaying(false);
    }
  }, [player]);

  const handleSeekForward = useCallback(() => {
    if (player) player.seekTo(player.getCurrentTime() + 15);
  }, [player]);

  const handleSeekBackward = useCallback(() => {
    if (player) player.seekTo(player.getCurrentTime() - 5);
  }, [player]);

  const handleIncreaseVolume = useCallback(() => {
    if (player) player.setVolume(player.getVolume() + 10);
  }, [player]);

  const handleDecreaseVolume = useCallback(() => {
    if (player) player.setVolume(player.getVolume() - 10);
  }, [player]);

  const handleToggleMute = useCallback(() => {
    if (player) player.isMuted() ? player.unMute() : player.mute();
  }, [player]);

  const handleTogglePlayPause = useCallback(() => {
    if (player) {
      if (player.getPlayerState() === 1) {
        player.pauseVideo();
      } else {
        player.playVideo();
      }
    }
  }, [player]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === 'Escape') handleStopVideo();
      if (e.shiftKey) {
        e.preventDefault();
        switch (e.keyCode) {
          case 32:
            handleTogglePlayPause();
            break;
          case 39:
            handleSeekForward();
            break;
          case 37:
            handleSeekBackward();
            break;
          case 38:
            handleIncreaseVolume();
            break;
          case 40:
            handleDecreaseVolume();
            break;
          case 77:
            handleToggleMute();
            break;
          // case 83:
          //   handleStopVideo();
          //   break;
          default:
            break;
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [
    handleDecreaseVolume,
    handleIncreaseVolume,
    handleSeekBackward,
    handleSeekForward,
    handleToggleMute,
    handleTogglePlayPause,
    handleStopVideo,
  ]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };
    if (playerState === 1) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [playerState]);

  if (!props.data) return null;

  if (title && title.toLowerCase().includes('[private video]')) {
    return null;
  }

  return (
    <motion.article
      initial="hidden"
      whileInView="visible"
      variants={variants}
      className={
        props.fullscreen ? `${styles.video} ${styles.fullscreen}` : styles.video
      }
      onClick={(e) => handleClick(e)}
    >
      <div className={styles.glow} />
      <AnimatePresence>
        <motion.div
          key={isPlaying}
          initial="hidden"
          animate={videoReady ? "visible" : "hidden"} // Animate when the video is ready
          exit="hidden"
          variants={variantsYT}
        >
          {isPlaying && (
            <>
              <IconClose className={styles.close} onClick={endPlayback} />
              <YouTube
                videoId={id}
                className={`${styles.youtube} ${styles.youtubeShown}`}
                opts={opts}
                onReady={handleVideoReady}
                onEnd={endPlayback}
                onStateChange={onStateChange}
                // onError={func}
              />
            </>
          )}
        </motion.div>
      </AnimatePresence>
      <div className={styles.image}>
        <img src={checkFallbackImage(image)} alt={title} loading="lazy" />
        {duration && (
          <span className={styles.date}>
            {Number.isInteger(props.data.duration)
              ? timeStringToUnixSeconds(props.data.duration)
              : props.data.duration}
          </span>
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.meta}>
          <div className={styles.metaInner}>
            <img
              src={`https://startyparty.nyc3.digitaloceanspaces.com/channels/${channel_id}.webp`}
              alt={props.data.channel}
              loading="lazy"
              width={22}
              height={22}
            />
            <span className={styles.channel}>{channel}</span>
          </div>
          <span className={styles.duration}>
            {view_count && (
              <span>
                <IconEye />
                {view_count && formatNumberWithSuffix(view_count)}
              </span>
            )}
            {pub && type !== "poestreams" && (
              <RelativeTimestamp timestamp={pub} />
            )}
          </span>
        </div>
        <h3 className={styles.title}>{title}</h3>
        <div className={styles.description}>
          <p>{description}</p>
        </div>
      </div>
    </motion.article>
  );
}